// ** MUI Imports
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
// ** Third Party Components
import { ClaimSearch } from "pages/organizations/components/molecules/claimSearch";
import { LocationDashboardItems } from "../locationDashboardItems";
import ScrollWraperList from "../../atoms/scrollWrapper/ScrollWrapperList";

export type ChatSidebarLeftType = {
  hidden: boolean;
  mdAbove: boolean;
  sidebarWidth: number | string;
  leftSidebarOpen: boolean;
  handleLeftSidebarToggle: () => void;
  permanentLeftBar: boolean;
  loading: boolean;
  hasNextPage: boolean;
  page: number;
  loadMoreLocations: () => void;
  claimView?: boolean;
};

export default function LocationDashboardList(props: ChatSidebarLeftType) {
  // ** Props
  const { hidden, mdAbove, sidebarWidth, leftSidebarOpen, handleLeftSidebarToggle, permanentLeftBar } = props;
  const { loading, hasNextPage, page, loadMoreLocations, claimView } = props;
  const desktopPermanentBar = permanentLeftBar ? "permanent" : "temporary";
  const desktopPermanentBarPosition = permanentLeftBar ? "static" : "absolute";
  return (
    <Drawer
      open={leftSidebarOpen}
      onClose={handleLeftSidebarToggle}
      variant={mdAbove ? desktopPermanentBar : "temporary"}
      ModalProps={{
        disablePortal: true,
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        zIndex: 1001,
        height: "100%",
        display: "block",
        position: mdAbove ? desktopPermanentBarPosition : "absolute",
        "& .MuiDrawer-paper": {
          boxShadow: "none",
          overflow: "hidden",
          width: sidebarWidth,
          position: mdAbove ? "static" : "absolute",

          borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
        },
        "& > .MuiBackdrop-root": {
          // borderRadius: 1,
          position: "absolute",
          zIndex: (theme) => theme.zIndex.drawer - 1,
        },
        "& :hover": {
          borderColor: "rgba(234, 234, 255, 0.12)",
        },
      }}
    >
      <Box>
        <ScrollWraperList hidden={hidden}>
          {claimView && <ClaimSearch />}
          <LocationDashboardItems
            loading={loading}
            hasNextPage={hasNextPage}
            page={page}
            loadMoreLocations={loadMoreLocations}
            claimView={claimView}
          />
        </ScrollWraperList>
      </Box>
    </Drawer>
  );
}
