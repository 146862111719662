/* eslint-disable no-shadow */
//** Reat imports */
import { useState, createContext, useContext, SetStateAction, Dispatch, useMemo, useRef } from "react";
//** Models */
import { IUsersListDOM } from "pages/users/models";
import { array50 } from "models";
/*
The idea of this context it is allow a mechanism to pass the information between the components of the page.
*/

type contextValues = {
  order: string;
  setOrder: Dispatch<SetStateAction<string>>;
  orderBy: any;
  setOrderBy: Dispatch<SetStateAction<any>>;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;

  usersArray: IUsersListDOM[];
  setUsersArray: Dispatch<SetStateAction<IUsersListDOM[]>>;
  clickedMarker: string | null;
  setClickedMarker: Dispatch<SetStateAction<string | null>>;
  statusFocus: any;
  load: boolean[];
  setLoad: Dispatch<SetStateAction<boolean[]>>;
};

const defaultValues = {
  order: "asc",
  setOrder: () => {},
  orderBy: "first_name",
  setOrderBy: () => {},
  selected: [],
  setSelected: () => {},
  page: 0,
  setPage: () => {},

  usersArray: [],
  setUsersArray: () => {},
  clickedMarker: null,
  setClickedMarker: () => {},
  statusFocus: null,
  load: array50,
  setLoad: () => {},
};

const UsersListContext = createContext<contextValues>(defaultValues);

const UsersListContextProvider = (props: any) => {
  const { children } = props;

  const [order, setOrder] = useState<string>("asc");
  const [orderBy, setOrderBy] = useState<any>("first_name");
  const [selected, setSelected] = useState<string[]>([]);
  const [load, setLoad] = useState<boolean[]>(array50);

  const [usersArray, setUsersArray] = useState<IUsersListDOM[]>([]);
  const [clickedMarker, setClickedMarker] = useState<string | null>(null);
  const statusFocus: any = useRef(null);

  //**Search */

  const contextValues = useMemo(
    () => ({
      order,
      setOrder,
      orderBy,
      setOrderBy,
      selected,
      setSelected,
      usersArray,
      setUsersArray,
      clickedMarker,
      setClickedMarker,
      statusFocus,
      load,
      setLoad,
    }),
    [order, orderBy, selected, usersArray, clickedMarker, statusFocus, load],
  );

  return <UsersListContext.Provider value={contextValues}>{children}</UsersListContext.Provider>;
};

export const useUsersListContext = () => {
  const context = useContext(UsersListContext);
  if (context === undefined) {
    throw new Error("UsersListContext must be used within a UsersListContextProvider");
  }
  return context;
};

export { UsersListContext, UsersListContextProvider };
