// ** MUI Imports
import Box from "@mui/material/Box";
import { MapDashboard } from "components";

// ** Custom Icon Import
import { ButtonToggleMap, ScrollWrapper, useLocationListContext } from "pages/locations";

type IProps = {
  hidden: boolean;
  permanentLeftBar: boolean;
  handleChangeLeftBar: () => void;
  handleLeftSidebarToggle: () => void;
  smAbove: boolean;
  loading?: boolean;
  claimView?: boolean;
  isListShow?: boolean;
  isLocationList?: boolean;
};

export default function LocationDashboardContent(props: IProps) {
  // ** Props
  const {
    hidden,
    permanentLeftBar,
    handleChangeLeftBar,
    smAbove,
    claimView,
    handleLeftSidebarToggle,
    loading,
    isListShow,
    isLocationList,
  } = props;
  const { locationsMapMarksArray } = useLocationListContext();

  const claimHeightDesktop = !claimView ? "80vh" : "81vh";
  const claimHeightMobile = !claimView ? "60vh" : "61vh";

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        height: "100%",
        backgroundColor: "action.hover",
      }}
    >
      <Box
        sx={
          smAbove
            ? { height: `${!claimView ? "calc(100% - 8.4375rem)" : "100%"}`, marginTop: "-80px" }
            : { height: `${!claimView ? "calc(100% - 8.4375rem)" : "100%"}`, marginTop: "-65px" }
        }
      >
        <ScrollWrapper hidden={hidden}>
          {claimView && (
            <ButtonToggleMap
              smAbove={smAbove}
              permanentLeftBar={permanentLeftBar}
              handleChangeLeftBar={
                !smAbove
                  ? () => {
                      handleChangeLeftBar();
                      handleLeftSidebarToggle();
                    }
                  : () => {
                      handleChangeLeftBar();
                    }
              }
            />
          )}

          <MapDashboard
            positions={locationsMapMarksArray}
            mapHeight={smAbove ? claimHeightDesktop : claimHeightMobile}
            options={{ mapTypeControlOptions: { position: 3 } }}
            loading={loading}
            isListShow={isListShow}
            isLocationList={isLocationList}
          />
        </ScrollWrapper>
      </Box>
    </Box>
  );
}
