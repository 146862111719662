//** MUI Imports */
import { CardContent } from "@mui/material";
import MuiCard, { CardProps } from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

//**Components Imports */
import BlankLayout from "core/layouts/BlankLayout";
import { StepperContent, StepperRequest, StepperHeader } from "./components";

//** Context */
import { RequestContextProvider } from "./context";

const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: "80%" },
}));

export default function RequestAccess() {
  return (
    <RequestContextProvider>
      <BlankLayout>
        <Box className="content-center">
          <Card sx={{ maxWidth: 900, backgroundColor: "background.default" }}>
            <CardContent sx={{ p: (theme) => `${theme.spacing(18, 15, 18)} !important` }}>
              <CardContent>
                <StepperHeader />
                <StepperRequest />
              </CardContent>

              <CardContent>
                <StepperContent />
              </CardContent>
            </CardContent>
          </Card>
        </Box>
      </BlankLayout>
    </RequestContextProvider>
  );
}
