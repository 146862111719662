import { useState } from "react";
//** MUI Imports*/
import { Box, Typography, useMediaQuery, Stack, Theme, Checkbox, IconButton, useTheme } from "@mui/material";
//** Components Imports*/
import { FilterChips, Input, InputApiCall, InputApiCallInfiniteScroll, Select, TableSearch } from "components";
import Translations from "layouts/components/Translations";
import { RoleGuard } from "guards";
import EditIcon from "@mui/icons-material/Edit";
import { PrivateRoutes, Roles } from "models";
import UserBulkActions from "pages/users/components/molecules/UserBulkActions/UserBulkActions";
//** Context */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
//** Hooks */
import { useTranslation } from "react-i18next";
import { useGetReduxUser } from "hooks";
import { useGetRoles } from "hooks/apiCalls";
import useGetSearchData from "pages/locations/pages/locationsList/hooks/useGetSearchData";
//** Icons */
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useGlobalContext } from "context/globalContext";
import { useNavigate } from "react-router-dom";
import GroupIcon from "@mui/icons-material/Group";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useUsersListContext } from "../../../context";
import UserImportOnHeader from "./UserImportOnHeader";
import useUsersSearch from "../../../hooks/useUsersSearch";

export default function AppListHeader({
  onSearch,
  isDashboard,
  embebed,
  invertBgHeader,
  showUserTitle,
}: {
  onSearch: () => void;
  isDashboard?: boolean;
  embebed?: boolean;
  invertBgHeader?: boolean;
  showUserTitle?: boolean;
}) {
  //** Context */
  const { usersHasFilter } = useListAndSearchContext();
  const { usersArray, setSelected, selected } = useUsersListContext();
  const firstNameSearchFn = useUsersSearch().userFirstNameSearch;
  const lastNameSearchFn = useUsersSearch().userLastNameSearch;
  const emailSearchFn = useUsersSearch().userEmailSearch;
  const statusUserSearch = useUsersSearch().userStatusSearch;
  const roleSearch = useUsersSearch().userRoleSearch;
  const organizationUserSearch = useUsersSearch().userOrganizationSearch;

  //** Hooks */
  const matches = useMediaQuery("(max-width:600px)");
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const MUITheme = useTheme();
  const navigate = useNavigate();
  const { getRoles, loading } = useGetRoles();

  const { t } = useTranslation();

  const optionsStatus = [t("OPTION-SELECT-1"), t("OPTION-SELECT-2")];

  const { getOrganizations, orgPage, hasNextPage, orgData, setOrgPage, loadingOrg, setOrgData } = useGetSearchData();
  const { setUserEditMode, userEditMode } = useGlobalContext();

  const numSelected = selected.length;
  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = usersArray.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const rowCount = usersArray.length;

  //**Chips */
  const filterChips = [
    {
      key: "first_name",
      label: "INPUT-FIRST-NAME",
      data: firstNameSearchFn.value,
    },
    {
      key: "last_name",
      label: "INPUT-LAST-NAME",
      data: lastNameSearchFn.value,
    },
    {
      key: "email",
      label: "INPUT-EMAIL",
      data: emailSearchFn.value,
    },
    {
      key: "role",
      label: "INPUT-ROLE",
      data: roleSearch.valueLabel,
    },
    {
      key: "status",
      label: "INPUT-STATUS",
      data: statusUserSearch.value,
    },
    {
      key: "organization",
      label: "GLOBAL-TITLE-ORGNAIZATION",
      data: organizationUserSearch.valueLabel,
    },
  ];

  //Filter Chips functions
  const handleDelete = (filterName: "first_name" | "last_name" | "email" | "role" | "status" | "organization") => {
    if (filterName === "first_name") firstNameSearchFn.clearByKey();
    if (filterName === "last_name") lastNameSearchFn.clearByKey();
    if (filterName === "email") emailSearchFn.clearByKey();
    if (filterName === "role") roleSearch.clearByKey();
    if (filterName === "status") statusUserSearch.clearByKey();
    if (filterName === "organization") organizationUserSearch.clearByKey();
    onSearch();
  };
  const handleDeleteAll = () => {
    firstNameSearchFn.clearByKey();
    lastNameSearchFn.clearByKey();
    emailSearchFn.clearByKey();
    roleSearch.clearByKey();
    statusUserSearch.clearByKey();
    organizationUserSearch.clearByKey();
    onSearch();
  };

  const [callStatus, setCallStatus] = useState<"success" | "error" | null>(null);

  const { userState } = useGetReduxUser();

  return (
    <Box
      sx={{ backgroundColor: embebed && underMd && !invertBgHeader ? "background.default" : "background.paper" }}
      pb={4}
      pt={embebed && underMd ? 2 : 8}
    >
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={4}
        px={3}
        mt={3}
        mb={1}
        justifyContent="space-between"
        alignItems={embebed ? "center" : ""}
      >
        <Box>
          {embebed && underMd && !showUserTitle ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          ) : (
            <Box ml={2} mt={2}>
              <Box display="flex" alignItems="center" onClick={() => {}}>
                {!embebed && (
                  <IconButton
                    sx={{ mr: 2 }}
                    onClick={() => {
                      navigate(`/${PrivateRoutes.LOCATIONS}`);
                    }}
                  >
                    <ArrowBackIosNewIcon sx={{ fontSize: 16, stroke: (theme: Theme) => `${theme.palette.text.primary}`, strokeWidth: 2 }} />
                  </IconButton>
                )}
                <GroupIcon sx={{ fontSize: 24, mr: 4 }} />
                <Typography
                  width="100%"
                  variant={embebed ? "h2" : "h1"}
                  fontWeight="700"
                  borderBottom={embebed && !underMd ? `2px solid ${MUITheme.palette.text.primary}` : ""}
                >
                  <Box>
                    {embebed ? (
                      <Translations text="GLOBAL-TITLE-USERS" toUpperCase />
                    ) : (
                      <Translations text="GLOBAL-TITLE-ALL-USERS" toUpperCase />
                    )}
                  </Box>
                </Typography>
              </Box>
            </Box>
          )}
          {userEditMode && (
            <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgOwner, Roles.organizationOwner, Roles.locationOwner]}>
              <Box display="flex" alignItems="center" ml={-4}>
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                />

                <Box display="flex" justifyContent="space-between">
                  <UserBulkActions onSearch={onSearch} hasSelectedItems={selected.length > 0} />
                </Box>
              </Box>
            </RoleGuard>
          )}
        </Box>

        <Box
          ml="auto"
          display="flex"
          flexWrap="wrap"
          flexDirection={underMd ? "column" : "row"}
          alignItems={underMd ? "flex-end" : "normal"}
        >
          {!embebed && (
            <Box>
              {userEditMode ? (
                <IconButton
                  sx={{ mr: 4 }}
                  onClick={() => {
                    setUserEditMode(false);
                    setSelected([]);
                  }}
                  disabled={loading}
                >
                  <CheckCircleIcon sx={{ fontSize: 20 }} />
                </IconButton>
              ) : (
                <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgOwner, Roles.organizationOwner, Roles.locationOwner]}>
                  <IconButton
                    sx={{ mr: 4 }}
                    onClick={() => {
                      setUserEditMode(true);
                    }}
                  >
                    <EditIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </RoleGuard>
              )}
            </Box>
          )}
          <Box mt={underMd ? 4 : 0}>
            <TableSearch
              isDashboard={isDashboard}
              noOptionsComponent={
                <Box>
                  <Stack direction={!matches ? "row" : "column"} spacing={4} mb={4}>
                    <Input name="usr-first-name-search" {...firstNameSearchFn} label={`${t("INPUT-FIRST-NAME")}`} fullWidth />
                    <Input name="usr-last-name-search" {...lastNameSearchFn} label={`${t("INPUT-LAST-NAME")}`} fullWidth />
                  </Stack>
                  <Box mb={4}>
                    <Input name="usr-email-search" {...emailSearchFn} label={`${t("INPUT-EMAIL")}`} fullWidth />
                  </Box>
                  <Stack direction={!matches ? "row" : "column"} spacing={4} mb={4}>
                    <Select
                      name="usr-status-search"
                      value={statusUserSearch.value || ""}
                      label={`${t("INPUT-STATUS")}`}
                      setValue={statusUserSearch.onChange}
                      options={optionsStatus}
                    />
                    <InputApiCall
                      name="usr-role-search"
                      externalValue={{ label: roleSearch.valueLabel || "", id: roleSearch.valueId || "" } || null}
                      setPropValue={roleSearch.onChange}
                      apiCallFunction={getRoles}
                      loading={loading}
                      label={`${t("INPUT-ROLE")}`}
                    />
                  </Stack>
                  {!embebed && !userState?.organization?.id && (
                    <Box mb={4}>
                      <InputApiCallInfiniteScroll
                        initialValue={{ label: organizationUserSearch.valueLabel || "", id: organizationUserSearch.valueId || "" } || null}
                        name="usr-organization-search"
                        setPropValue={organizationUserSearch.onChange}
                        changeWithInputText
                        apiCallFunction={getOrganizations}
                        loading={loadingOrg}
                        label={`${t("GLOBAL-TITLE-ORGNAIZATION")}`}
                        hasNextPage={hasNextPage}
                        page={orgPage}
                        options={orgData}
                        setPage={setOrgPage}
                        setOptions={setOrgData}
                        search
                      />
                    </Box>
                  )}
                </Box>
              }
              onReset={handleDeleteAll}
              onSubmit={(e: any) => {
                e.preventDefault();
                onSearch();
              }}
            />
          </Box>
        </Box>
      </Box>

      {usersHasFilter && (
        <Stack direction="row" spacing={4} alignItems="center" ml={4} my={6}>
          <FilterChips filterChips={filterChips} handleDelete={handleDelete} handleDeleteAll={handleDeleteAll} />
        </Stack>
      )}
      <UserImportOnHeader callStatus={callStatus} setCallStatus={setCallStatus} />
    </Box>
  );
}
