//** Imports componens */
import { ModalConfirm } from "components";
//**Mui components */
import { Box, Switch } from "@mui/material";
//** Hooks */
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useOrganizationInfoContext } from "../../../context";

export default function SuspendSection() {
  //** Hooks */
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  //**Context */
  const { active, setActive } = useOrganizationInfoContext();

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleChangeActive = () => {
    setActive(!active);
    handleCloseModal();
  };

  return (
    <Box mt={2} ml={4}>
      <Switch checked={!active} onChange={handleOpenModal} size="small" />
      <ModalConfirm
        open={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleChangeActive}
        title={active ? t("ORGANIZATION-MODAL-ACTIVE-TITLE") : `${t("ORGANIZATION-SUSPEND-TITLE")}?`}
        description={active ? t("ORGANIZATION-MODAL-ACTIVE-DESCRIPTION") : t("ORGANIZATION-MODAL-SUSPEND-DESCRIPTION")}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
    </Box>
  );
}
