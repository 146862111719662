/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
//**Hooks */
import { useAsync, useFetchAndLoad, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation as useLocationMUI } from "react-router-dom";
//**Context */
import { useLocationContext } from "pages/locations/context";
//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
//**Services */
import { getAllPurposesAPI, getOneLocationAPI } from "pages/locations/services";
import { IGetOneLocation, ILocationDOM } from "pages/locations/models";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import { useGlobalContext } from "context/globalContext";
import { useUserContext } from "pages/users";
import { PrivateRoutes } from "models";
//**Adapter */
import { locationGetOneAdapter } from "../adapters";

export default function useLocation(id: string) {
  //**Hooks */
  const { loading, callEndpoint } = useFetchAndLoad();
  const fetchPurposes = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  const { addToTheEndOfHistory, createCustomHistory } = useBreadcrumbs();
  const { breadcrumbsHistory } = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocationMUI();

  //** Redux */
  const userState = useSelector((store: AppStore) => store.user);
  const { organization } = userState;

  //**Context */
  //** Step */
  const {
    setPurpose,
    setDataCopy,
    setInitalUrl,
    setLogoText,
    setMainPictureText,
    setAdditionalPicturesText,
    setBrandsPicturesText,
    updateToggle,
    setIsHQLocation,
  } = useLocationContext();
  //** Others Step */
  const { setName, setOrganization, setLogo, setApplicableBrand, setCategory, setIsVisible } = useLocationContext();
  const { setEmail, setPhone, setWebsite, setFax, setOpeningHours, setMarketCountry, setMapId } = useLocationContext();
  const { setAddress1, setAddress2, setCity, setPostalCode, setLatitude, setLongitude, setState, setRegion } = useLocationContext();
  const { setPositions } = useGlobalContext();
  //* location Services */
  const { setDisplayOrder, setIsPremium, setIsFactory, setPrimaryContact, setHqPrimaryContact, setIsOnlineOnly, setSBDConnectContact } =
    useLocationContext();
  //* SBDUSE*/
  const {
    setDescriptionSBDUS,
    setAmountOfEmployees,
    setFacilitySquareFootage,
    setBusiness,
    setMainLocationImage,
    setLocationImagesAdditional,
    setBrandsPictures,
    clearBasicInformation,
  } = useLocationContext();
  const { setSustainable, setYearFounded, setMngDirector, setKeyContacts } = useLocationContext();
  //**User info */
  const userContext = useUserContext();
  //** Data From APi */
  const getOneLocation: any = async (id: string) => {
    clearBasicInformation();
    //Calling get all API
    const response = await callEndpoint(getOneLocationAPI(id), () => handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"));
    const responsePurposes = await fetchPurposes.callEndpoint(getAllPurposesAPI(id));
    return {
      data: {
        location: response,
        purpose: responsePurposes,
      },
    };
  };

  const adaptInfo = (data: IGetOneLocation) => {
    //Save the adapted data and the last page and length for the pagination
    //**CLEAR user data */
    userContext.clearBasicInformation();

    const adaptedData: ILocationDOM = locationGetOneAdapter(data?.location?.data?.result);
    const purposesDataResponse = data?.purpose?.data?.result?.items;
    //
    if (organization?.id && adaptedData.organizationId !== organization.id) {
      navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.LIST}`);
      handleCreateToast(t("ALERT-INFO-INVALID-LOCATION"), "info");
    }

    //Breadcrumb
    if (organization?.id) {
      createCustomHistory([
        {
          url: `/${PrivateRoutes.ORGANIZATIONS_DASHBOARD}`,
          label: organization.name,
          index: 0,
        },
        {
          label: `${t("GLOBAL-TITLE-LOCATION")}: ${adaptedData.name}`,
          url: "",
          index: breadcrumbsHistory.length,
        },
      ]);
    } else if (location.pathname.includes(`${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}`)) {
      addToTheEndOfHistory({
        label: adaptedData.name,
        url: `/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}/${adaptedData.id}`,
        index: breadcrumbsHistory.length,
      });
    }

    //** Basic information */
    setName(adaptedData.name);
    setOrganization({ label: adaptedData?.organizationData?.name, id: adaptedData?.organizationId });
    const selectedBrands = adaptedData?.brandData?.map((item) => ({ label: item.name, id: item.id, code: item.code }));
    setApplicableBrand(selectedBrands);
    setCategory(adaptedData.categoriesProducts);
    setIsHQLocation(adaptedData.isHeadQuarter);
    setIsVisible(adaptedData.isVisible);
    //Logo need to be File type
    //Convert url to blob to file
    if (adaptedData?.organizationData?.logo) {
      setLogoText(adaptedData?.organizationData?.logo as string);
      // eslint-disable-next-line prefer-const
      let xhr = new XMLHttpRequest();
      xhr.open("GET", `${adaptedData.organizationData?.logo}.png`);
      xhr.responseType = "arraybuffer";
      xhr.onload = function () {
        const blob = new Blob([xhr.response]);
        const file = new File([blob], "logo.png", { type: "image/png" });
        setLogo([file]);
        setDataCopy((data: any) => {
          return {
            ...data,
            logoFile: [file],
          };
        });
      };
      xhr.send();
    } else {
      setLogo([]);
    }

    //** Contact */
    setEmail(adaptedData.contact.email);
    setPhone(adaptedData.contact.phone);
    setFax(adaptedData.contact.fax);
    setWebsite(
      adaptedData?.contact.website?.includes("https://")
        ? adaptedData.contact.website.slice(8)
        : adaptedData?.contact.website?.includes("http://")
        ? adaptedData.contact.website.slice(7)
        : adaptedData.contact.website,
    );
    setInitalUrl(
      adaptedData?.contact.website?.includes("https://")
        ? "https://"
        : adaptedData?.contact.website?.includes("http://")
        ? "http://"
        : "https://",
    );
    const days: string[] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const openingHoursData = days.map((day, index) => ({
      label: day,
      id: index,
      opening: adaptedData.openHours[`${day.toLocaleLowerCase()}`]?.opening,
      closing: adaptedData.openHours[`${day.toLocaleLowerCase()}`]?.closing,
      is_enabled: adaptedData.openHours[`${day.toLocaleLowerCase()}`]?.is_enabled,
    }));
    setOpeningHours([...openingHoursData]);

    //** Address */
    setMapId(adaptedData.address.mapsId);
    setAddress1(adaptedData.address.address1);
    setAddress2(adaptedData.address.address2);
    setMarketCountry({ label: adaptedData.marketOrCountry, id: adaptedData.marketOrCountry });
    setCity(adaptedData.address.city);
    setPostalCode(adaptedData.address.postalCode);
    setRegion({ label: adaptedData.address.region, id: adaptedData.address.region });
    setLatitude(`${adaptedData.address.coordinates[0]}`);
    setLongitude(`${adaptedData.address.coordinates[1]}`);
    setPositions([
      {
        lat: adaptedData.address.coordinates[0],
        lng: adaptedData.address.coordinates[1],
      },
    ]);
    setState(adaptedData.address.state);

    //**Aditional Steps */
    const purposesData = purposesDataResponse.map((item) => ({ label: item.name, id: item.code, code: item.code }));
    const purposes = purposesData.filter((item) => adaptedData.purposeCodes.includes(item.code));
    setPurpose(purposes);

    setIsPremium(adaptedData.isPremium);
    setIsFactory(adaptedData.isFactory);
    setIsOnlineOnly(adaptedData.isOnlineOnly);
    setDisplayOrder(adaptedData.displayOrder);

    //SBD US
    const additionalImagesCopy: any[] = [];
    const brandsImagesCopy: any[] = [];

    setDescriptionSBDUS(adaptedData.description);
    setAmountOfEmployees(adaptedData.amountOfEmployees);
    setFacilitySquareFootage(adaptedData.facilitySquareFootage);
    setBusiness(adaptedData.business);

    setSustainable(adaptedData.sustainability);
    setYearFounded(adaptedData.yearFounded);

    setMngDirector(adaptedData.contact.managingDirectorOrLeader);
    setPrimaryContact(adaptedData.primaryContactData);
    setSBDConnectContact(adaptedData.sbdConnectData);
    setHqPrimaryContact({
      label: `${adaptedData.primaryContactData?.firstName}`,
      id: adaptedData.primaryContactData?.id,
    });

    const emptyKeyContact = [
      {
        name: "",
        title: "",
        email: "",
        phone: "",
        id: `${Math.random()}`,
        isValidEmail: true,
      },
    ];

    setKeyContacts(
      adaptedData.contact.keyContacts?.length === 0
        ? [...emptyKeyContact]
        : adaptedData.contact.keyContacts?.map((item) => ({
            email: item.email,
            name: item.name,
            phone: item.phone,
            title: item.title,
            id: `${Math.random()}`,
            isValidEmail: true,
          })),
    );
    const mainImageFromAPI = adaptedData.mainImage;
    const additionalImagesFromAPI = adaptedData.images;
    const brandsImagesFromAPI = adaptedData.brandManufactured;

    if (mainImageFromAPI && !window.location.pathname.includes("information")) {
      setMainPictureText(mainImageFromAPI as string);
      const xhr = new XMLHttpRequest();
      xhr.open("GET", `${mainImageFromAPI}.png`);
      xhr.responseType = "arraybuffer";
      xhr.onload = function () {
        const blob = new Blob([xhr.response]);
        const file = new File([blob], "main_image.png", { type: "image/png" });
        setMainLocationImage([file]);
        setDataCopy((data: any) => {
          return {
            ...data,
            mainImageSBDUS: [file],
          };
        });
      };
      xhr.send();
    } else {
      setMainLocationImage([]);
    }
    if (additionalImagesFromAPI && !window.location.pathname.includes("information")) {
      for (let i = 0; i < additionalImagesFromAPI.length; i += 1) {
        setAdditionalPicturesText((prev) => [...prev, additionalImagesFromAPI[i] as string]);
        const xhr = new XMLHttpRequest();
        xhr.open("GET", `${additionalImagesFromAPI[i]}.png`);
        xhr.responseType = "arraybuffer";
        xhr.onload = function () {
          const blob = new Blob([xhr.response]);
          const file = new File([blob], `aditionalpicures${i}.png`, { type: "image/png" });
          setLocationImagesAdditional((prev) => [...prev, file]);
          additionalImagesCopy.push(file);
        };
        xhr.send();
      }
    } else {
      setLocationImagesAdditional([]);
    }
    if (brandsImagesFromAPI && !window.location.pathname.includes("information")) {
      for (let i = 0; i < brandsImagesFromAPI.length; i += 1) {
        setBrandsPicturesText((prev) => [...prev, brandsImagesFromAPI[i] as string]);
        const xhr = new XMLHttpRequest();
        xhr.open("GET", `${brandsImagesFromAPI[i]}.png`);
        xhr.responseType = "arraybuffer";
        xhr.onload = function () {
          const blob = new Blob([xhr.response]);
          const file = new File([blob], `brand_images${i}.png`, { type: "image/png" });
          setBrandsPictures((prev) => [...prev, file]);

          brandsImagesCopy.push(file);
        };
        xhr.send();
      }
    } else {
      setBrandsPictures([]);
    }
    //** User info */
    userContext.setLocation({ label: adaptedData.name, id: adaptedData.id });
    userContext.setOrganization({ label: adaptedData.organizationData.name, id: adaptedData.organizationId });

    setDataCopy({
      name: adaptedData.name,
      organizationId: adaptedData.organizationId,
      organizationData: adaptedData.organizationData,
      brands: selectedBrands,
      categoriesProducts: adaptedData.categoriesProducts,
      isVisible: adaptedData.isVisible,
      email: adaptedData.contact.email,
      phone: adaptedData.contact.phone,
      website: adaptedData.contact.website,
      fax: adaptedData.contact.fax,
      openHours: openingHoursData,

      address1: adaptedData.address.address1,
      address2: adaptedData.address.address2,
      city: adaptedData.address.city,
      postalCode: adaptedData.address.postalCode,
      country: adaptedData.marketOrCountry,
      state: adaptedData.address.state,
      latitude: +adaptedData.address.coordinates[0],
      longitude: +adaptedData.address.coordinates[1],
      purposes,
      additionalImagesCopy,
      brandsImagesCopy,
      isHqLocation: adaptedData.isHeadQuarter,
      primaryContact: adaptedData.primaryContactData,
      sbdConnect: adaptedData.sbdConnectData,
    });
  };

  useAsync(
    () => getOneLocation(id),
    adaptInfo,
    () => {},
    [updateToggle, organization?.id],
  );

  return { loading, loadingPurpose: fetchPurposes.loading };
}
