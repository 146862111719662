import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Theme, useMediaQuery } from "@mui/material";

import { useGlobalContext } from "context/globalContext";
import { RoleGuard } from "guards";
import { Roles } from "models";
import { ButtonsInformation } from "pages/organizations";
import { useGetReduxUser } from "hooks";
import { useParams } from "react-router-dom";

export default function OrganizationEditButtonResp({ orgLandingEmbebed }: { orgLandingEmbebed?: boolean }) {
  const { setOrgEditMode, orgEditMode, setUserEditMode, setLocEditMode } = useGlobalContext();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { id } = useParams();
  const { userState } = useGetReduxUser();
  const multiOrgCanEdit =
    userState?.roles === Roles.multiOrgOwner ? userState.organizations?.find((item) => item.id.includes(`${id}`)) : true;

  return (
    <>
      {multiOrgCanEdit && (
        <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.organizationEditor, Roles.organizationOwner, Roles.multiOrgOwner]}>
          {!orgEditMode ? (
            <IconButton
              onClick={() => {
                setOrgEditMode(true);
                setUserEditMode(true);
                setLocEditMode(true);
              }}
            >
              <EditIcon sx={{ fontSize: underMd ? 20 : 24 }} />
            </IconButton>
          ) : (
            <Box>
              <ButtonsInformation orgLandingEmbebed={orgLandingEmbebed} />
            </Box>
          )}
        </RoleGuard>
      )}
    </>
  );
}
