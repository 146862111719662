/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
// ** MUI Imports
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import { Checkbox, Grid, List, ListItemButton, ListItemIcon, Skeleton, useMediaQuery, useTheme } from "@mui/material"; //** Models */

import { PrivateRoutes } from "models";
//** Components */
import { Chip } from "components";
import { ClaimLocationButton } from "pages/locations/pages/locationClaim/components";
//** Hooks */
import { useTranslation } from "react-i18next";
import useEmptyCardsLocationData from "hooks/useEmptyCardsLocationData";
import { EmptyTableCards, ILocationsListDOM, useLocationListContext } from "pages/locations";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useNavigate } from "react-router-dom";
import useLocTables from "hooks/useLocTables";
import { useListAndSearchContext } from "context/ListsAndSearchContext";

import { Methods } from "constants/globalConstants";
import { TooltipError } from "components/atoms/TooltipError";
import WarningTooltip from "components/molecules/WarningTooltip/WarningTooltip";

export default function LocationDashboardItems({
  loading,
  hasNextPage,
  page,
  loadMoreLocations,
  claimView,
}: {
  loading: boolean;
  hasNextPage: boolean;
  page: number;
  loadMoreLocations: () => void;
  claimView?: boolean;
}) {
  // ** State
  const [hoverItem, setHoverItem] = useState<string>("");
  //** Hooks */
  const navigate = useNavigate();
  const smAbove = useMediaQuery("(min-width:900px)");
  const { locationHasFilter, locationStatus } = useListAndSearchContext();
  const {
    locationsMapListArray,
    activeLocationItemList,
    setActiveLocationItemList,
    statusFocus,
    clickedMarker,
    setClickedMarker,
    selectedClaim,
    setSelectedClaim,
  } = useLocationListContext();

  const { t } = useTranslation();
  const themeMUI = useTheme();

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: page === 0 ? () => {} : () => loadMoreLocations(),
    rootMargin: "0px 0px 400px 0px",
  });

  const handleChatClick = (type: "location", id: string, coordinates: [number, number]) => {
    setActiveLocationItemList({ type, id, coordinates });
  };

  const { dataCards } = useEmptyCardsLocationData();

  const { handleClickTableCheckbox, isSelected, handleClickTableCheckboxMobile } = useLocTables(
    setSelectedClaim,
    selectedClaim,
    locationsMapListArray,
  );

  const [scrolling, setScrolling] = useState(false);
  const [longPressTimer, setLongPressTimer] = useState<number | NodeJS.Timeout>(0);

  const handleTouchStart = (itemId: string, hq: boolean) => {
    if (scrolling) return;
    setHoverItem(itemId);

    const timer = setTimeout(
      () => {
        // Handle long press here (e.g., item selection)
        handleClickTableCheckboxMobile(itemId, hq);
      },
      selectedClaim.length > 0 ? 300 : 500,
    ); // Adjust the delay as needed for your long press threshold
    setLongPressTimer(timer);
  };

  const handleTouchMove = () => {
    if (!scrolling) {
      //When moving the user its scrolling
      setScrolling(true);
      //clear the timer (USER CAN'T SELECT WHILE SCROLLING)
      clearTimeout(longPressTimer);
    }
  };

  const handleTouchEnd = () => {
    setHoverItem("");
    // Clear the timer when the touch ends
    // Start a timer when the user touches an item
    setScrolling(false);
    clearTimeout(longPressTimer);
  };

  useEffect(() => {
    if (clickedMarker) {
      statusFocus?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center", //
      });
    }
    setClickedMarker(null);
  }, [clickedMarker]);

  if (loading && locationsMapListArray.length === 0) {
    return (
      <Box mx={8}>
        <Skeleton width="100%" height={80} />
        <Skeleton width="100%" height={80} />
        <Skeleton width="100%" height={80} />
        <Skeleton width="100%" height={80} />
        <Skeleton width="100%" height={80} />
      </Box>
    );
  }
  if (locationsMapListArray.length === 0) {
    const emptyListValidation = locationHasFilter.length > 0;
    return (
      <>
        <Box textAlign="center" mx={8} my={16}>
          <Typography noWrap variant="h3" sx={{ color: "text.secondary" }} mb={4}>
            {emptyListValidation || claimView ? `${t("EMPTY-FILTER-LOCATIONS-TITLE")}` : `${t("EMPTY-LOCATIONS-TITLE")}`}
          </Typography>
          <Typography noWrap variant="h2" sx={{ color: "text.disabled" }} whiteSpace="normal">
            {emptyListValidation || claimView ? `${t("EMPTY-FILTER-LOCATIONS-DESCRIPTION")}` : `${t("EMPTY-LOCATIONS-DESCRIPTION")}`}
          </Typography>
        </Box>
        {!emptyListValidation && !claimView && (
          <Grid container spacing={4} justifyContent="center">
            {dataCards.map((item, index) => (
              <EmptyTableCards key={index} data={dataCards[index]} name="custom-radios-delivery" gridProps={{ xs: 10, mb: 8 }} />
            ))}
          </Grid>
        )}
      </>
    );
  }

  return (
    <Box ref={rootRef} sx={{ maxHeight: `${claimView ? "55vh" : "80vh"}`, overflowY: "auto", maxWidth: "100%" }}>
      <List>
        {locationsMapListArray?.map((item: ILocationsListDOM, index: number) => {
          const activeCondition =
            activeLocationItemList !== null && activeLocationItemList.id === item.id && activeLocationItemList.type === "location";

          const isItemSelected = isSelected(item.id);
          const isItemHover = item.id === hoverItem;
          return (
            <ListItem
              key={index}
              disablePadding
              sx={{ "&:not(:last-child)": { mb: 1.5 } }}
              ref={`${clickedMarker}` === item.id ? statusFocus : null}
              onMouseEnter={smAbove ? () => setHoverItem(item.id) : undefined}
              onMouseLeave={smAbove ? () => setHoverItem("") : undefined}
              onTouchStart={smAbove ? undefined : () => handleTouchStart(item.id, item.isHeadQuarter)}
              onTouchEnd={smAbove ? undefined : () => handleTouchEnd()}
              onTouchMove={smAbove ? undefined : () => handleTouchMove()}
            >
              <ListItemButton
                disableRipple
                onMouseEnter={() => handleChatClick("location", item.id, item.address.coordinates)}
                // onMouseLeave={() => setActiveLocationItemList(null)}
                sx={{
                  px: 2.5,
                  py: 4,
                  width: "100%",
                  borderRadius: 1,
                  alignItems: "flex-start",
                  ...((activeCondition || isItemSelected) && {
                    backgroundColor: (theme) => `${isItemSelected ? theme.palette.primary.main : theme.palette.divider} !important`,
                  }),
                }}
              >
                <ListItemIcon>
                  {locationStatus === Methods.UNCLAIMED && (
                    <Checkbox
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      color={themeMUI.palette.mode === "dark" ? "default" : "white"}
                      onClick={() => handleClickTableCheckbox(item.id, item.isHeadQuarter)}
                      sx={
                        isItemHover || isItemSelected || selectedClaim.length > 0
                          ? { "& .MuiSvgIcon-root": { fontSize: 24 }, visibility: "visible" }
                          : { visibility: "hidden" }
                      }
                      checked={isItemSelected}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    my: 0,
                    ml: 4,
                    mr: 1.5,
                    "& .MuiTypography-root": {
                      ...((activeCondition || isItemSelected) && {
                        color: `${themeMUI.palette.mode === "dark" ? "common.white" : isItemSelected ? "common.white" : "text.secondary"}`,
                      }),
                    },
                  }}
                  primary={
                    <Box display="flex">
                      <Typography fontWeight={600} noWrap sx={{ ...(!activeCondition ? { color: "text.secondary" } : {}) }}>
                        {item.name}
                      </Typography>
                      {!item.hasRequiredFields && !claimView && <TooltipError dashboard />}
                      {item.warnings && item.warnings?.length > 0 && !claimView && <WarningTooltip />}
                    </Box>
                  }
                  secondary={
                    <Box>
                      <Typography noWrap variant="body2" sx={{ ...(!activeCondition && { color: "text.disabled" }) }}>
                        {item.address.address1}
                      </Typography>
                    </Box>
                  }
                  onClick={
                    !claimView
                      ? () => {
                          navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}/${item.id}`);
                        }
                      : () => {
                          handleClickTableCheckbox(item.id, item.isHeadQuarter);
                        }
                  }
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  {claimView && locationStatus === Methods.UNCLAIMED && (
                    <Box mr={8}>
                      <ClaimLocationButton isItemSelected={isItemSelected} itemsId={[item.id]} />
                    </Box>
                  )}
                  {!claimView && (
                    <>
                      {item.isVisible ? (
                        <Chip
                          skin={themeMUI.palette?.mode === "light" ? (activeCondition || isItemSelected ? undefined : "light") : undefined}
                          disableTheme={themeMUI.palette?.mode === "light"}
                          size="small"
                          label={t("CHIP-VISIBLE")}
                          color="success"
                          sx={{ fontSize: "1.4rem", mr: 4 }}
                        />
                      ) : (
                        <Chip
                          skin={themeMUI.palette?.mode === "light" ? (activeCondition || isItemSelected ? undefined : "light") : undefined}
                          disableTheme={themeMUI.palette?.mode === "light"}
                          size="small"
                          label={t("CHIP-HIDDEN")}
                          color="secondary"
                          sx={{ fontSize: "1.4rem", mr: 4 }}
                        />
                      )}
                    </>
                  )}
                </Box>
              </ListItemButton>
            </ListItem>
          );
        })}
        {hasNextPage && (
          <ListItem ref={infiniteRef}>
            <Skeleton width="100%" height={80} />
          </ListItem>
        )}
      </List>
    </Box>
  );
}
