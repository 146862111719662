import { SyntheticEvent } from "react";
//**MUI IMPORTS */
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
//**Hooks */
import { Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
//** CONTEXT */
import { useOrganizationInfoContext } from "../../../context";

export default function OrganizationsTabs() {
  //**States */
  const { tabValue, setTabValue } = useOrganizationInfoContext();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  //**Hooks */
  const { t } = useTranslation();

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    event.preventDefault();
    setTabValue(newValue);
  };

  if (underMd) {
    return (
      <Tabs value={tabValue} onChange={handleChange} variant="fullWidth">
        <Tab
          value="1"
          label={<Typography variant="h2">{`${t("TAB-ORGANIZATION-2")}`}</Typography>}
          sx={{ "&:focus": { outline: "0px auto -webkit-focus-ring-color" }, fontSize: 14 }}
        />
        <Tab
          value="2"
          label={<Typography variant="h2">{`${t("TAB-ORGANIZATION-3")}`}</Typography>}
          sx={{ "&:focus": { outline: "0px auto -webkit-focus-ring-color" }, fontSize: 14 }}
        />
      </Tabs>
    );
  }
  return (
    <Tabs value={tabValue} onChange={handleChange} variant="fullWidth">
      <Tab value="1" label={`${t("TAB-ORGANIZATION-1")}`} sx={{ "&:focus": { outline: "0px auto -webkit-focus-ring-color" } }} />
      <Tab value="2" label={`${t("TAB-ORGANIZATION-2")}`} sx={{ "&:focus": { outline: "0px auto -webkit-focus-ring-color" } }} />
      <Tab value="3" label={`${t("TAB-ORGANIZATION-3")}`} sx={{ "&:focus": { outline: "0px auto -webkit-focus-ring-color" } }} />
    </Tabs>
  );
}
