import { useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { Chip, Image } from "components";
//** Icons */
import LanguageIcon from "@mui/icons-material/Language";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIcon from "@mui/icons-material/Phone";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import { PrivateRoutes } from "models";
import { useTranslation } from "react-i18next";

interface IOrgDetailData {
  logoText: string;
  name: string;
  url: string;
  emailOrganization: string;
  phoneOrganization: string;
  addressOrg: string;
  id: string;
  locationId: string;
}
export default function OrgListDetail({ data, isAnyOrgSelected }: { data: IOrgDetailData; isAnyOrgSelected: boolean }) {
  const [load, setLoad] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}/${data.id}`, { replace: false });
  };
  return (
    <>
      {isAnyOrgSelected ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          sx={{ backgroundColor: "background.paper" }}
          px={6}
        >
          <Box>
            <Box display="flex" mb={8} mt={4}>
              <Image
                src={typeof data.logoText === "string" ? data.logoText : ""}
                alt={`logo-${data.name}`}
                name={`logo-${data.name}`}
                onLoad={(e: any) => {
                  if (e.type === "load") setLoad(false);
                }}
                style={{ objectFit: "fill", display: load ? "none" : "block", maxWidth: "50%" }}
              />

              <Skeleton sx={{ display: load ? "block" : "none" }} animation="wave" variant="rectangular" width={80} height={30} />
            </Box>

            <Box width="80%">
              <Box
                sx={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}
                width="calc(100% - 35px)"
                onClick={(e: any) => {
                  e.stopPropagation();
                  window.open(`${data.url}`, "_blank");
                }}
              >
                <Typography variant="h2" mb={4} sx={{ overflow: "hidden" }}>
                  <Box display="flex" alignItems="flex-start">
                    <LanguageIcon sx={{ fontSize: 24, mr: 2 }} />
                    {`${data.url}`}
                  </Box>
                </Typography>
              </Box>

              <Typography
                variant="h2"
                sx={{
                  width: "calc(100% - 40px)",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                  cursor: "pointer",
                }}
                mb={4}
                onClick={(e: any) => {
                  e.stopPropagation();
                  window.location.href = `mailto:${data.emailOrganization}`;
                }}
              >
                <Box display="flex" alignItems="flex-start">
                  <AlternateEmailIcon sx={{ fontSize: 24, mr: 2 }} />
                </Box>
                {data.emailOrganization || "------"}
              </Typography>
              <Typography
                variant="h2"
                sx={{ width: "100%" }}
                mb={4}
                onClick={() => {
                  navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}/${data.locationId}`, { replace: false });
                }}
              >
                <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
                  <Chip label="HQ" size="small" color="success" sx={{ mr: 2 }} />
                  {`${data.addressOrg || "------"}`}
                  <ArrowForwardIosIcon sx={{ fontSize: 20, ml: 4 }} />
                </Box>
              </Typography>
              <Typography variant="h2" sx={{ width: "100%" }} mb={4}>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  sx={{ cursor: "pointer" }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    window.location.href = `tel:${data.phoneOrganization}`;
                  }}
                >
                  <PhoneIcon sx={{ fontSize: 24, mr: 2 }} />
                  {data.phoneOrganization}
                </Box>
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{ backgroundColor: "background.default", borderRadius: "8px 0 0 8px", cursor: "pointer", mb: 6 }}
              py={4}
              px={8}
              onClick={handleNavigate}
            >
              <Typography variant="h1" sx={{ width: "100%" }} display="flex" alignItems="center">
                {data.name}
                <ArrowForwardIosIcon sx={{ fontSize: 24, ml: 4 }} />
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" justifyContent="center" height="100%" px={6}>
          <Typography variant="h2" sx={{ width: "100%" }} display="flex" fontWeight="700">
            {t("ORGANIZATION-LIST-NOT-SELECTED")}
          </Typography>
        </Box>
      )}
    </>
  );
}
