//** Imports componens */
import { ModalConfirm, SectionsTitles } from "components";
//**Mui components */
import { Box, FormControlLabel, Switch } from "@mui/material";
//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
//** Hooks */
import { useTranslation } from "react-i18next";
import { Roles } from "models";
import { useState } from "react";
import { useUserContext } from "../../../context";

export default function SuspendSection() {
  //**Redux  */
  const userState = useSelector((store: AppStore) => store.user);
  const { roles } = userState;

  //** Hooks */
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  //**Context */
  const { active, setActive, radioRole, role } = useUserContext();

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleChangeActive = () => {
    setActive(!active);
    handleCloseModal();
  };

  const canSuspendOrgOwner = roles === Roles.organizationOwner && (radioRole === Roles.superAdmin || radioRole === Roles.generalViewOnly);
  const canSuspendLocationOwner =
    roles === Roles.locationOwner && role?.label !== Roles.locationEditor && role?.label !== Roles.locationOwner;

  const disableForViewOnlyRole =
    roles === Roles.generalViewOnly || roles === Roles.viewOnly || roles === Roles.locationEditor || roles === Roles.organizationEditor;

  return (
    <Box my={8} mx={1}>
      {!canSuspendOrgOwner && !canSuspendLocationOwner && (
        <>
          <ModalConfirm
            open={showModal}
            handleClose={handleCloseModal}
            handleConfirm={handleChangeActive}
            title={active ? t("USER-MODAL-ACTIVE-TITLE") : `${t("USER-SUSPEND-TITLE")}?`}
            description={active ? t("USER-MODAL-ACTIVE-DESCRIPTION") : t("USER-MODAL-SUSPEND-SUBTITLE")}
            sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
            sxTitle={{ fontSize: "2rem !important" }}
          />
          <SectionsTitles h2 title={`${t("USER-SUSPEND-TITLE")}`} subTitle={`${t("USER-SUSPEND-SUBTITLE")}`} />
          <FormControlLabel
            disabled={disableForViewOnlyRole}
            control={<Switch checked={!active} onChange={handleOpenModal} />}
            label={!active ? t("ORGANIZATION-SWITCH-ACTIVE") : t("ORGANIZATION-SWITCH-SUSPEND")}
          />
        </>
      )}
    </Box>
  );
}
