import { Box, ListItemIcon, MenuItem, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import MenuMoreBulk from "components/molecules/MenuMoreBulk/MenuMoreBulk";

export default function BulkOrgMoreOptions({
  handleOption1,
  handleOption2,
  hasSelectedItems,
}: {
  handleOption1: () => void;
  handleOption2: () => void;
  hasSelectedItems: boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const { t } = useTranslation();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  return (
    <MenuMoreBulk anchorEl={anchorEl} handleClick={handleClick} handleClose={handleClose} open={open}>
      <MenuItem
        id="demo-positioned-button"
        aria-controls={open2 ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open2 ? "true" : undefined}
      >
        <Box onClick={handleClick2} display="flex" alignItems="center" width="100%">
          <ListItemIcon>
            <ArrowBackIosNewIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="body2">{t("BULK-FREC-MENU")}</Typography>
        </Box>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl2}
          open={open2}
          onClose={handleClose2}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            disabled={!hasSelectedItems}
            onClick={() => {
              handleClose2();
              handleOption1();
            }}
          >
            {t("BULK-FREC-SUB-MENU-1")}
          </MenuItem>
          <MenuItem
            disabled={!hasSelectedItems}
            onClick={() => {
              handleClose2();
              handleOption2();
            }}
          >
            {t("BULK-FREC-SUB-MENU-2")}
          </MenuItem>
        </Menu>
      </MenuItem>
    </MenuMoreBulk>
  );
}
