/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
//**Hooks */
import { useAsync, useFetchAndLoad, useToastMui } from "hooks";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
//**Models */
import { IOrganizationFromAPI, IOrganizationListDOM } from "pages/organizations/models";

//**Context */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { useOrganizationContext } from "pages/organizations/context";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import { useGlobalContext } from "context/globalContext";
import { useUserContext } from "pages/users";
import { PrivateRoutes } from "models";
import { useOrganizationInfoContext } from "../context";
//**Adapter */
import { organizationGetOneAdapter } from "../adapters";
//**Services */
import { getOneOrganizationAPI } from "../services";

export default function useOrganization(id: string) {
  //**Hooks */
  const { loading, callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const location = useLocation();
  const { t } = useTranslation();
  const { addToTheEndOfHistory, createCustomHistory } = useBreadcrumbs();
  const { breadcrumbsHistory } = useGlobalContext();

  //**Context */
  const { setActive, setFrecuency, setDataCopy, toggleGetData } = useOrganizationInfoContext();
  const { setName, setDescription, setLogo, setUrl, cleatBasicInformation, setLogoText, setInitalUrl, setCityOrganization } =
    useOrganizationContext();
  const userContext = useUserContext();
  const { setEmailOrganization, setPhoneOrganization, setLocHqId, setPrimaryContact, setHqPrimaryContact } = useOrganizationContext();
  const userState = useSelector((store: AppStore) => store.user);
  const { organization } = userState;

  //** Data From APi */
  const getOneOrganization: any = async (id: string) => {
    cleatBasicInformation();
    //Calling get all API
    const response = await callEndpoint(getOneOrganizationAPI(id), () => handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"));
    return response;
  };

  const adaptInfo = (data: IOrganizationFromAPI) => {
    //Save the adapted data and the last page and length for the pagination
    const adaptedData: IOrganizationListDOM = organizationGetOneAdapter(data?.result);

    //Breadcrumb;
    if (organization?.id) {
      createCustomHistory([
        {
          url: `/${PrivateRoutes.ORGANIZATIONS_DASHBOARD}`,
          label: adaptedData.name,
          index: 0,
        },
        {
          label: t("ORGANIZATION-DETAIL"),
          url: "",
          index: breadcrumbsHistory.length,
        },
      ]);
    } else if (location.pathname.includes(`${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}`)) {
      addToTheEndOfHistory({
        label: adaptedData.name,
        url: `/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}/${adaptedData.id}`,
        index: breadcrumbsHistory.length,
      });
    }
    //Data
    setActive(adaptedData.isSuspended);
    setFrecuency({
      label: `${adaptedData.notificationTime}` === "7" ? t("FRECUENCY-1") : t("FRECUENCY-2"),
      value: `${adaptedData.notificationTime}`,
    });
    setName(adaptedData.name);
    setDescription(adaptedData.description);
    setUrl(
      adaptedData?.hqInformation.website?.includes("https://")
        ? adaptedData.hqInformation.website.slice(8)
        : adaptedData?.hqInformation.website?.includes("http://")
        ? adaptedData.hqInformation.website.slice(7)
        : adaptedData.hqInformation.website,
    );
    setInitalUrl(
      adaptedData?.hqInformation.website?.includes("https://")
        ? "https://"
        : adaptedData?.hqInformation.website?.includes("http://")
        ? "http://"
        : "https://",
    );
    setDataCopy({
      name: adaptedData.name,
      description: adaptedData.description,
      id: adaptedData.id,
      isSuspended: adaptedData.isSuspended,
      notificationTime: adaptedData.notificationTime,
      url: adaptedData?.hqInformation.website?.includes("https://")
        ? adaptedData.hqInformation.website.slice(8)
        : adaptedData?.hqInformation.website?.includes("http://")
        ? adaptedData.hqInformation.website.slice(7)
        : adaptedData.hqInformation.website,
      initialUrl: adaptedData?.hqInformation.website?.includes("https://")
        ? "https://"
        : adaptedData?.url?.includes("http://")
        ? "http://"
        : "https://",
      phoneOrganization: adaptedData.hqInformation.phone,
      emailOrganization: adaptedData.hqInformation.email,
      hqId: adaptedData.hqInformation.id,
    });
    //**Contact  */
    setEmailOrganization(adaptedData.hqInformation.email);
    setPhoneOrganization(adaptedData.hqInformation.phone);
    setCityOrganization(adaptedData.hqInformation.address);
    setLocHqId(adaptedData.hqInformation.id);
    setPrimaryContact(adaptedData.primaryContactData);
    setHqPrimaryContact({
      label: `${adaptedData.primaryContactData?.firstName}`,
      id: adaptedData.primaryContactData?.id,
    });

    //** Users modal  */
    userContext.setOrganization({ label: adaptedData.name, id: adaptedData.id });
    //Logo need to be File type
    //Convert url to blob to file
    if (adaptedData?.logo) {
      setLogoText(adaptedData?.logo as string);
      const xhr = new XMLHttpRequest();
      xhr.open("GET", `${adaptedData.logo}.png`);
      xhr.responseType = "arraybuffer";

      xhr.onload = function () {
        const blob = new Blob([xhr.response]);
        const file = new File([blob], "logo.png", { type: "image/png" });
        setLogo([file]);
        setDataCopy((data: any) => {
          return {
            ...data,
            logo: [file],
          };
        });
      };
      xhr.send();
    } else {
      setLogo([]);
    }
  };

  useAsync(
    () => getOneOrganization(id),
    adaptInfo,
    () => {},
    [organization, toggleGetData],
  );

  return { loading, getOneOrganization };
}
