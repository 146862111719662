/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-expressions */
import { Box, Checkbox, IconButton, Skeleton, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import Translations from "layouts/components/Translations";
import { PrivateRoutes, Roles } from "models";
import { useOrganizationContext, useOrganizationInfoContext } from "pages/organizations";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { RoleGuard } from "guards";
import PlaceIcon from "@mui/icons-material/Place";
import { LocBulkActions } from "pages/locations/components";
import { useGlobalContext } from "context/globalContext";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useInputValueContext } from "hooks";
import { Input } from "components";
import { useLocationListContext } from "../../../context";

export default function TextHeaderLoc({
  mainPage,
  loading,
  onSearch,
  embebed,
  fromOrg,
}: {
  mainPage?: boolean;
  loading?: boolean;
  onSearch: Function;
  embebed?: boolean;
  fromOrg?: boolean;
}) {
  //** On responsive view this header appear on org details */
  const { name, setName, setLocationHq } = useOrganizationContext();
  const { locationsMapListArray, selectedClaim, setSelectedClaim } = useLocationListContext();
  const { setOrgActiveBulkActions } = useListAndSearchContext();
  const { setToggleGetData } = useOrganizationInfoContext();
  const { setOrgEditMode, setUserEditMode, setLocEditMode, setMapView } = useGlobalContext();

  const nameInput = useInputValueContext("", setName);
  const { locEditMode, orgEditMode } = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const MUITheme = useTheme();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);

  const adminUsers = [Roles.superAdmin, Roles.generalViewOnly, Roles.multiOrgOwner];
  const numSelected = selectedClaim.length;
  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = locationsMapListArray.map((n) => ({ id: n.id, isHQ: n.isHeadQuarter }));
      setSelectedClaim(newSelected);
      return;
    }
    setSelectedClaim([]);
  };

  const rowCount = locationsMapListArray.length;

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" gap={4} mt={locEditMode && embebed ? 0 : 3} mb={1} alignItems="flex-start">
      <Box>
        {embebed && underMd ? (
          <></>
        ) : (
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={() => {
                if (orgEditMode) {
                  setOrgEditMode(false);
                  setUserEditMode(false);
                  setLocEditMode(false);
                  setMapView(false);
                  setOrgActiveBulkActions(false);
                  setToggleGetData((prev) => !prev);
                  //** Clear dropdown to get the info again */
                  setLocationHq({ id: "", label: "" });
                  return;
                }
                // eslint-disable-next-line no-lonely-if

                if (fromOrg) {
                  navigate(-1);
                } else {
                  adminUsers.find((item) => item === userState?.roles)
                    ? navigate(`/${PrivateRoutes.ORGANIZATIONS}`)
                    : navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}/${userState.organization?.id}`);
                }
              }}
              sx={{ mr: 2 }}
            >
              <ArrowBackIosNewIcon sx={{ fontSize: 16, stroke: (theme: Theme) => `${theme.palette.text.primary}`, strokeWidth: 2 }} />
            </IconButton>
            {/* On mobile view this header can appear on a organization view */}
            {location.pathname.includes(PrivateRoutes.ORGANIZATIONS) ? (
              <CorporateFareIcon sx={{ fontSize: 24, mr: 2 }} />
            ) : (
              <PlaceIcon sx={{ fontSize: 24, mr: 4 }} />
            )}

            {mainPage ? (
              <Typography variant="h1" fontWeight="700" borderBottom={embebed ? `2px solid ${MUITheme.palette.text.primary}` : ""}>
                <Translations text={embebed ? "GLOBAL-TITLE-LOCATIONS" : "GLOBAL-TITLE-ALL-LOCATIONS"} />
              </Typography>
            ) : (
              <>
                {loading ? (
                  <Skeleton variant="rounded" width={140} height={30} />
                ) : (
                  <>
                    {orgEditMode ? (
                      <Box width="100%">
                        <Input name="org-email" value={name} onChange={nameInput.onChange} fullWidth size="small" />{" "}
                      </Box>
                    ) : (
                      <Typography
                        variant="h1"
                        fontWeight="700"
                        sx={{
                          maxWidth: "calc(38vw - 15px)",
                        }}
                        mt={1}
                      >
                        {name}
                      </Typography>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        )}
        {locEditMode && !fromOrg && (
          <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgOwner, Roles.organizationOwner, Roles.organizationEditor]}>
            <Box display="flex" alignItems="center" mt={2} ml={-4}>
              <Box display="flex" alignItems="center">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                />
              </Box>
              <Box>
                <LocBulkActions onSearch={onSearch} hasSelectedItems={selectedClaim.length > 0} />
              </Box>
            </Box>
          </RoleGuard>
        )}
      </Box>
    </Box>
  );
}
