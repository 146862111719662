import { useEffect, useState } from "react";
//** MUI Imports */
import { Box, Checkbox, Divider, Stack, Typography, useMediaQuery } from "@mui/material";

//** Hooks */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useTranslation } from "react-i18next";
import { useFetchAndLoad, useInputValueContext } from "hooks";
//** Constants */
import { Methods } from "constants/globalConstants";
//** Components */
import { AutoComplete, ClaimMoreSearch, FilterChips, Input } from "components";
import { getAllRegionsAPI, useLocationListContext } from "pages/locations";
import { IRegion } from "models";
import { ClaimLocationButton } from "pages/locations/pages/locationClaim/components";
import { ClaimChips } from "../../../../locations/components/atoms/claimChips";

export default function ClaimSearch() {
  const {
    locationStatus,
    claimStateSearch,
    claimCitySearch,
    claimCountrySearch,
    setClaimStateSearch,
    setClaimCitySearch,
    setClaimCountrySearch,
    setClaimSearchToggle,
    claimSearchToggle,
  } = useListAndSearchContext();
  const {
    selectedClaim,
    setSelectedClaim,
    locationsMapListArray,
    latitudeClaimMap,
    longitudeClaimMap,
    setLatitudeClaimMap,
    setLongitudeClaimMap,
    nameLocSearch,
    setNameLocSearch,
  } = useLocationListContext();
  const name = useInputValueContext("", setNameLocSearch);
  const [marketOptionsDropdown, setMarketOptionsDropdown] = useState<any>([]);
  const [regionOptions, setRegionOptions] = useState<IRegion[]>([]);

  //** Hooks */
  const fetchRegion = useFetchAndLoad();
  const matches = useMediaQuery("(max-width:600px)");
  const cityInput = useInputValueContext("", setClaimCitySearch);
  const stateInput = useInputValueContext("", setClaimStateSearch);

  const { t } = useTranslation();

  const searchToggle = () => {
    setClaimSearchToggle(!claimSearchToggle);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    searchToggle();
  };

  //**Chips */

  //!nombre market country - estado y ciudad
  const filterChips = [
    {
      key: "search",
      label: "Search",
      data: nameLocSearch,
    },
    {
      key: "state",
      label: "INPUT-STATE",
      data: claimStateSearch,
    },
    {
      key: "country",
      label: "INPUT-MARKET-COUNTRY",
      data: claimCountrySearch?.label,
    },
    {
      key: "city",
      label: "INPUT-CITY",
      data: claimCitySearch,
    },
    {
      key: "coordinates",
      label: "INPUT-COORDINATES",
      data: latitudeClaimMap && longitudeClaimMap ? `${latitudeClaimMap},${longitudeClaimMap}` : "",
    },
  ];

  //Filter Chips functions
  const handleDelete = (filterName: "search" | "state" | "country" | "city" | "coordinates") => {
    if (filterName === "search") setNameLocSearch("");
    if (filterName === "state") setClaimStateSearch("");
    if (filterName === "country") setClaimCountrySearch({ label: "", id: "" });
    if (filterName === "city") setClaimCitySearch("");
    if (filterName === "coordinates") {
      setLatitudeClaimMap(undefined);
      setLongitudeClaimMap(undefined);
    }

    searchToggle();
  };
  const handleDeleteAll = () => {
    setNameLocSearch("");
    setClaimCitySearch("");
    setClaimStateSearch("");
    setClaimCountrySearch({ label: "", id: "" });
    setLatitudeClaimMap(undefined);
    setLongitudeClaimMap(undefined);
    searchToggle();
  };

  //clear state when component unmount
  useEffect(() => {
    return () => {
      setNameLocSearch("");
      setClaimCitySearch("");
      setClaimStateSearch("");
      setClaimCountrySearch({ label: "", id: "" });
    };
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = locationsMapListArray.map((n) => ({ id: n.id, isHQ: n.isHeadQuarter }));
      setSelectedClaim(newSelected);
      return;
    }
    setSelectedClaim([]);
  };

  const numSelected = selectedClaim.length;
  const rowCount = locationsMapListArray.length;

  const getCountryData = async () => {
    //Get all the regions/markets data on all the countrys

    const autocompleteData = regionOptions.map((item: IRegion) => ({ markets: item.markets }));

    //create a regions/markets array data on all the countrys information
    let array: { label: string; id: string }[] = [];
    autocompleteData.forEach((item) => {
      item.markets?.forEach((market: any) => {
        array = [...array, { label: market.code, id: market.code }];
      });
    });
    setMarketOptionsDropdown([...array]);
  };
  // eslint-disable-next-line consistent-return
  const getRegionData = async () => {
    //Get country data sort by name
    try {
      const query: any = { sort: "asc(name)" };
      const response = await fetchRegion.callEndpoint(getAllRegionsAPI({ query }));
      const { data } = response;

      //Get all the regions/markets data on all the countrys
      const autocompleteData: IRegion[] = data.result.items.map((item: IRegion) => ({ label: item.code, id: item.code }));
      if (regionOptions.length === 0) setRegionOptions(data?.result?.items);
      return autocompleteData;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRegionData();
  }, []);

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Box px={6} py={4}>
        <Box display="flex">
          {locationStatus === Methods.UNCLAIMED && (
            <Box>
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={handleSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
              />
            </Box>
          )}
          <Box ml={2} display="flex" justifyContent="center" flexDirection="column" mb={selectedClaim.length !== 0 ? 7 : 0} width="100%">
            <Box display="flex" width="100%" justifyContent="space-between" mt={4}>
              <Typography variant="h3">{t("LOCATION-CLAIMS-ACTION")}</Typography>
              {selectedClaim.length > 0 && locationStatus === Methods.UNCLAIMED && (
                <ClaimLocationButton itemsId={selectedClaim.map((item) => item.id)} isItemSelected={false} />
              )}
            </Box>

            {selectedClaim.length !== 0 ? (
              <Box>
                <Typography variant="h6" color="primary">
                  {t("GLOBAL-SELECTED")} {selectedClaim.length}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography variant="body1" mt={2}>
                  {t("CLAIM-LOCATION-DESCRIPTION")}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={4} p={4}>
        <Stack
          direction="row"
          spacing={4}
          alignItems="center"
          mb={
            claimCitySearch || claimCountrySearch?.label || claimStateSearch || nameLocSearch || latitudeClaimMap || longitudeClaimMap
              ? 8
              : 0
          }
        >
          <FilterChips filterChips={filterChips} handleDelete={handleDelete} handleDeleteAll={handleDeleteAll} />
        </Stack>

        <form noValidate onSubmit={handleSubmit} autoComplete="chrome-off">
          <Box display="flex" width="100%">
            <ClaimMoreSearch
              noOptionsComponent={
                <>
                  <Stack direction={!matches ? "row" : "column"} spacing={4} mb={8}>
                    <Input
                      name="loc-claim-search"
                      label={`${t("INPUT-SEARCH-MAP")}`}
                      value={nameLocSearch}
                      onChange={name.onChange}
                      fullWidth
                    />
                    <AutoComplete
                      fullWidth
                      value={claimCountrySearch}
                      options={marketOptionsDropdown}
                      setValue={(valueState: any) => {
                        setClaimCountrySearch(valueState);
                      }}
                      name="loc-market-country"
                      blurOnSelect
                      inputLabel={`${t("INPUT-MARKET-COUNTRY")}`}
                      onClick={getCountryData}
                    />
                  </Stack>
                  <Stack direction={!matches ? "row" : "column"} spacing={4} mb={8}>
                    <Input
                      name="loc-state"
                      value={claimStateSearch}
                      onChange={stateInput.onChange}
                      label={`${t("INPUT-STATE")}`}
                      fullWidth
                    />

                    <Input name="loc-city" value={claimCitySearch} onChange={cityInput.onChange} label={`${t("INPUT-CITY")}`} fullWidth />
                  </Stack>
                </>
              }
              onReset={() => {
                setClaimCitySearch("");
                setClaimCountrySearch({ label: "", id: "" });
                setClaimStateSearch("");
                searchToggle();
              }}
              onSubmit={handleSubmit}
            />
          </Box>
        </form>
      </Box>
      <ClaimChips />
    </Box>
  );
}
