import { lazy } from "react";
import { PrivateRoutes } from "models";
import { Navigate, Route } from "react-router-dom";
import { RoutesWithNotFound } from "utilities";
import { UserContextProvider } from "./context/UserContext";

const UsersRequestList = lazy(() => import("./pages/usersRequestList/UsersRequestsList"));
const UsersRequest = lazy(() => import("./pages/usersRequest/UsersRequest"));

export default function UsersRequestsNavigation() {
  return (
    <UserContextProvider>
      <RoutesWithNotFound>
        <Route path="/" element={<Navigate to={PrivateRoutes.LIST} />} />
        <Route path={PrivateRoutes.LIST} element={<UsersRequestList />} />
        <Route path={PrivateRoutes.INFORMATION} element={<UsersRequest />} />
      </RoutesWithNotFound>
    </UserContextProvider>
  );
}
