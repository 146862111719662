import { useEffect, useState } from "react";
/* eslint-disable no-unused-vars */
//** MUI imports */
import Box from "@mui/material/Box";
import { Fade, Grid, Skeleton, Stack } from "@mui/material";
//** Hooks */
import { useEmptyCardsLocationData } from "hooks";
//** Context */
import { IOrganizationListDOM } from "pages/organizations/models";
import { useListAndSearchContext } from "context/ListsAndSearchContext";

//** Components imports */
import EmptyTableCards from "pages/locations/components/molecules/emptyTableCards/EmptyTableCards";
import { EmptyListText } from "components";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { OrgSpeedDial } from "components/responsiveComponents";
import { useOrganizationListContext } from "../../../context";
import { useGetOrganizations } from "../../../hooks";
import { AppListHeader } from "../../atoms";
import TableResponsiveRow from "../../responsive/TableResponsiveRow/TableResponsiveRow";
import { OrgListDetail } from "../OrgListDetail";
import { OrgsSearchFields } from "../../../hooks/useOrganizationsSearch";

export default function OrganizationsTable() {
  const [selectedOrgData, setSelectedOrgData] = useState({} as IOrganizationListDOM);
  //Table management
  const { loading, hasNextPage, loadMoreOrganizations, onSearch } = useGetOrganizations(true);
  const { organizationHasFilter } = useListAndSearchContext();
  const { organizationsArray } = useOrganizationListContext();

  const { organizationsPage, orgActiveBulkActions, setOrgActiveBulkActions } = useListAndSearchContext();
  //**Search */
  const { searchParamsOrgs } = useListAndSearchContext();
  const nameSearch = searchParamsOrgs.get(OrgsSearchFields.name);
  const urlSearch = searchParamsOrgs.get(OrgsSearchFields.url);
  const statusSearch = searchParamsOrgs.get(OrgsSearchFields.status);
  //Navigation for the table
  const [infiniteRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore:
      organizationsPage === 0
        ? () => {}
        : () => loadMoreOrganizations({ name: nameSearch, is_suspended: statusSearch, website_url: urlSearch, nowPage: organizationsPage }),
    rootMargin: "0px 0px 400px 0px",
  });
  const hasData = organizationHasFilter?.length === 0 ? organizationsArray.length !== 0 : true;

  const { dataOrganizationsCards } = useEmptyCardsLocationData();
  const handleClick = (data: IOrganizationListDOM) => {
    setSelectedOrgData(data);
  };

  useEffect(() => {
    setOrgActiveBulkActions(false);
  }, []);

  return (
    <>
      <AppListHeader onSearch={onSearch} />
      <Box display="flex">
        <Box display="block" width="70%">
          <Box
            height={`calc(100vh - ${
              orgActiveBulkActions
                ? `226px - ${organizationHasFilter ? "70px" : "0px"}`
                : `180px - ${organizationHasFilter ? "70px" : "0px"}`
            })`}
            overflow="scroll"
          >
            {loading && organizationsArray.length === 0 ? (
              <Stack spacing={4}>
                {Array.from({ length: 12 }).map((_, i) => (
                  <Skeleton key={i} width="100%" height={60} variant="rounded" />
                ))}
              </Stack>
            ) : (
              <Box pt={2}>
                {organizationsArray?.map((item, i) => (
                  <TableResponsiveRow data={item} index={i} onClick={() => handleClick(item)} />
                ))}
                {(hasNextPage || loading) && <Skeleton width="100%" height={80} ref={infiniteRef} />}

                <Box display="flex" justifyContent="center">
                  {!hasData && (
                    <Grid container spacing={4} justifyContent="center" my={8} ml={0} width="95vw">
                      {dataOrganizationsCards.map((item, index) => (
                        <EmptyTableCards
                          key={index}
                          data={dataOrganizationsCards[index]}
                          name="custom-radios-delivery"
                          gridProps={{ md: 4, sm: 12, xs: 12 }}
                        />
                      ))}
                    </Grid>
                  )}
                  {organizationHasFilter?.length > 0 && organizationsArray.length === 0 && <EmptyListText />}
                </Box>
              </Box>
            )}
          </Box>
          <Fade in={orgActiveBulkActions}>
            <Box position="relative">
              <OrgSpeedDial absolute />
            </Box>
          </Fade>
        </Box>
        <Box width="30%" mt={2} pl={2}>
          <OrgListDetail
            data={{
              logoText: selectedOrgData.logo as string,
              id: selectedOrgData.id,
              name: selectedOrgData.name,
              url: selectedOrgData?.hqInformation?.website,
              emailOrganization: selectedOrgData?.hqInformation?.email,
              phoneOrganization: selectedOrgData?.hqInformation?.phone,
              addressOrg: selectedOrgData?.hqInformation?.address,
              locationId: selectedOrgData?.hqInformation?.id,
            }}
            isAnyOrgSelected={!!selectedOrgData.id}
          />
        </Box>
      </Box>
    </>
  );
}
