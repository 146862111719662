/* eslint-disable no-nested-ternary */
//** MUI */
import { Box, Skeleton, Stack, useMediaQuery } from "@mui/material";
//** Hooks */
import useInfiniteScroll from "react-infinite-scroll-hook";
import { EmptyListText } from "components";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
//** Components */

import { useUsersListContext } from "pages/users/pages/usersList/context";
import { AppListHeader } from "pages/users/pages/usersList/components/atoms";
import { useGlobalContext } from "context/globalContext";
import useGetUsersDashboard from "pages/users/pages/usersList/hooks/useUsersDashboard";

import TableResponsiveRow from "../TableResponsiveRow/TableResponsiveRow";

//**Context */

export default function UserTableResponsive({
  locationId,
  organizationId,
  embebed,
  staticTop,
  height,
  invertBg,
  invertBgHeader,
  locationLanding,
  showUserTitle,
}: {
  locationId?: string;
  organizationId?: string;
  embebed?: boolean;
  staticTop?: boolean;
  height?: string;
  invertBg: boolean;
  invertBgHeader?: boolean;
  locationLanding?: boolean;
  showUserTitle?: boolean;
}) {
  const underMediumHeight = useMediaQuery("(max-height: 650px)");
  const underHeight800 = useMediaQuery("(max-height: 800px)");
  //** Context */
  const { usersArray } = useUsersListContext();
  const { usersHasFilter } = useListAndSearchContext();
  const { userEditMode } = useGlobalContext();

  const { loading, hasNextPage, loadMoreUsers, onSearch, page } = useGetUsersDashboard(`${organizationId}`, `${locationId}`, embebed);

  const [infiniteRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: page === 0 ? () => {} : () => loadMoreUsers(),
    rootMargin: "0px 0px 400px 0px",
  });

  const isOdd = (num: number) => num % 2 === 0;

  const locLandingHeigth = underMediumHeight ? "calc(100vh - 195px)" : height || "calc(100vh - 430px)";
  const orgLandingHeigth = underHeight800
    ? userEditMode
      ? "405px"
      : "435px"
    : height || `calc(100vh - ${userEditMode ? "430px" : "405px"})`;

  return (
    <Box sx={{ backgroundColor: isOdd(usersArray.length) && !invertBg ? "background.default" : "background.paper" }}>
      <AppListHeader onSearch={onSearch} embebed={embebed} invertBgHeader={invertBgHeader} showUserTitle={showUserTitle} />

      {loading && usersArray.length === 0 ? (
        <Stack spacing={4} mt={8}>
          {Array.from({ length: 8 }).map((_, i) => (
            <Skeleton key={i} width="100%" height={50} variant="rounded" />
          ))}
        </Stack>
      ) : (
        <Box maxHeight={embebed ? (underHeight800 ? "1000px" : "calc(100vh - 405px)") : "calc(100vh - 250px)"} overflow="scroll">
          <Box height={staticTop ? (locationLanding ? locLandingHeigth : orgLandingHeigth) : ""} overflow="scroll">
            {usersArray?.map((item, i) => (
              <TableResponsiveRow data={item} index={i} alwaysEdit={embebed} invertBg={invertBg} embebed={!organizationId} />
            ))}
            {(hasNextPage || loading) && <Skeleton width="100%" height={80} ref={infiniteRef} />}

            <Box display="flex" justifyContent="center">
              {usersHasFilter?.length > 0 && usersArray.length === 0 && <EmptyListText />}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
