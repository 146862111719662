import { Backdrop, Fade, SpeedDial, SpeedDialAction, Theme, useMediaQuery } from "@mui/material";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { IDataButtonExpand } from "components/molecules/ButtonExpand/ButtonExpand";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { RoleGuard } from "guards";
import { PrivateRoutes, Roles } from "models";
import { ModalUserExport } from "pages/organizations/components/molecules/ModalUserExport";
import { UserCreateModal } from "pages/users/components/molecules/UserCreateModal";
import useExportUsers from "pages/users/hooks/useExportUsers";
import { UserImportOnHeader } from "pages/users/pages/usersList/components/atoms";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function UsersSpeedDial({
  absolute,
  embebed,
  organizationId,
  locationId,
}: {
  absolute?: boolean;
  embebed?: boolean;
  organizationId?: string;
  locationId?: string;
}) {
  // ** init trigger

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { openImportModalUser, setOpenImportModalUser, openAddModal, setOpenAddModal, openExport, openImportModal, setOpenexport } =
    useListAndSearchContext();
  const [callStatus, setCallStatus] = useState<"success" | "error" | null>(null);
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const { exportUsers } = useExportUsers({
    organizationId: organizationId || "",
    locationId: locationId || "",
    embebed,
  });

  const handleCloseExport = () => setOpenexport(false);
  const handleOpenExport = () => setOpenexport(true);

  const actions: IDataButtonExpand[] = [
    {
      label: t("BUTTON-IMPORT"),
      onClick: () => {
        setOpenImportModalUser(true);
        setCallStatus(null);
      },
    },
    {
      label: t("BUTTON-EXPORT"),
      onClick: () => {
        handleOpenExport();
      },
    },
    {
      label: t("BUTTON-INVITE-USER"),
      onClick: () => {
        navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.CREATE}`, { replace: false });
      },
    },
  ];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const authorizedRoles = [Roles.superAdmin, Roles.organizationOwner, Roles.locationOwner, Roles.multiOrgOwner];

  return (
    <>
      <Fade in={!openAddModal && !openImportModalUser && !openExport && !openImportModal}>
        <div>
          <RoleGuard authorizedRoles={authorizedRoles}>
            <Backdrop open={open} sx={{ zIndex: 999 }} onClick={handleClose} />
            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              sx={{
                position: absolute ? "absolute" : "fixed",
                bottom: (theme) => (absolute ? theme.spacing(20) : theme.spacing(30)),
                right: (theme) => (absolute ? theme.spacing(6) : theme.spacing(underMd ? 6 : 12)),
                zIndex: 1000,
                "& .MuiButtonBase-root": { width: 48, height: 48 },
                "& .MuiSpeedDialAction-staticTooltipLabel": { fontSize: "1.5rem", cursor: "pointer !important" },
                "& .MuiSpeedDial-actions": { marginBottom: actions.length === 2 ? "-117px" : "-138px", marginLeft: "-20px" },
                "& .MuiSpeedDialAction-fab": { visibility: "hidden", width: 15, height: 15, fontSize: 2 },
                "& .MuiSpeedDialIcon-icon": { fontSize: 28, fontWeight: 700, mt: -0.5 },
                "& .MuiSpeedDialAction-staticTooltip": { height: 43 },
              }}
              icon={<SpeedDialIcon />}
              onClick={() => {
                setOpen(!open);
              }}
              onOpen={handleOpen}
              open={open}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  data-testid={`usr-sd-test-${action.label}`}
                  key={action.label}
                  tooltipTitle={action.label}
                  tooltipOpen
                  onClick={() => {
                    // eslint-disable-next-line no-unused-expressions
                    action.onClick && action.onClick();
                    handleClose();
                  }}
                />
              ))}
            </SpeedDial>
          </RoleGuard>
        </div>
      </Fade>
      <UserImportOnHeader callStatus={callStatus} setCallStatus={setCallStatus} />
      <UserCreateModal
        open={openAddModal}
        handleClose={() => {
          setOpenAddModal(false);
        }}
      />
      <ModalUserExport open={openExport} handleExport={exportUsers} handleClose={handleCloseExport} />
    </>
  );
}
