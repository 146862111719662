import { Alert, Box, Dialog, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import MenuMoreBulk from "components/molecules/MenuMoreBulk/MenuMoreBulk";
import useGetSearchData from "pages/locations/pages/locationsList/hooks/useGetSearchData";
import { Button, InputApiCallInfiniteScroll } from "components";
import { useLocationListContext } from "pages/locations/pages";
import { useExportLocation } from "pages/locations/hooks";
import { ModalLocationExports } from "../ModalLocationExports";

interface IOrganizationOption {
  label: string | null;
  id: string | null;
}
export default function BulkLocMoreOptions({
  BulkActions,
  handleBulk,
  hasSelectedItems,
  hasHQSelected,
}: {
  BulkActions: any;
  handleBulk: Function;
  hasSelectedItems: boolean;
  hasHQSelected: boolean;
}) {
  //** States */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [organizationBulk, setOrganizationBulk] = useState<IOrganizationOption>({ label: "", id: "" });
  const open = Boolean(anchorEl);

  //** States modal*/
  const [openExport, setOpenExport] = useState(false);
  const handleCloseExp = () => setOpenExport(false);
  const handleOpenExp = () => setOpenExport(true);

  //** Hooks */
  const theme = useTheme();
  const { selectedClaim } = useLocationListContext();
  const { loading, exportLocations } = useExportLocation();

  const { t } = useTranslation();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    handleBulk({ organization_id: organizationBulk.id, action: BulkActions.ORGANIZATION });
  };

  const { getOrganizations, orgPage, hasNextPage, orgData, setOrgPage, loadingOrg, setOrgData } = useGetSearchData();

  return (
    <>
      <MenuMoreBulk anchorEl={anchorEl} handleClick={handleClick} handleClose={handleClose} open={open}>
        <MenuItem
          disabled={!hasSelectedItems}
          onClick={() => {
            setOpenDialog(true);
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">{t("BULK-CHANGE-ORG")}</Typography>
        </MenuItem>
        <MenuItem
          disabled={loading || !hasSelectedItems}
          onClick={() => {
            handleOpenExp();
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">{t("MENU-EXPORT")}</Typography>
        </MenuItem>
      </MenuMoreBulk>
      {openDialog && (
        <Dialog fullWidth open={openDialog} fullScreen={false} onClose={() => setOpenDialog(false)}>
          <Box sx={{ top: 0, width: "100%", position: "sticky" }}>
            <form
              onSubmit={(e) => {
                onSubmit(e);
                setOpenDialog(false);
              }}
            >
              <Box
                sx={{
                  p: 10,
                  overflow: "auto",
                  borderTop: `1px solid ${theme.palette.divider}`,
                  height: "100%",
                }}
              >
                <Typography variant="h3" mb={12}>{`Change the organization of  ${selectedClaim.length} locations?`}</Typography>
                <InputApiCallInfiniteScroll
                  initialValue={organizationBulk}
                  name="loc-organization-search"
                  setPropValue={setOrganizationBulk}
                  changeWithInputText
                  apiCallFunction={getOrganizations}
                  loading={loadingOrg}
                  label={`${t("GLOBAL-TITLE-ORGNAIZATION")}`}
                  hasNextPage={hasNextPage}
                  page={orgPage}
                  options={orgData}
                  setPage={setOrgPage}
                  setOptions={setOrgData}
                  disabled={hasHQSelected}
                />
                {hasHQSelected && (
                  <Alert severity="error" sx={{ "& .MuiPaper-root": { padding: "0" }, mt: 8 }}>
                    {`${t("LOCATIONS-CHANGE-ORG-MODAL-ERROR-HQ")}`}
                  </Alert>
                )}
                {organizationBulk?.label && (
                  <Alert severity="info" sx={{ "& .MuiPaper-root": { padding: "0" }, mt: 8 }}>
                    {`${t("BULK-ORG-INFO")} ${organizationBulk.label}`}
                  </Alert>
                )}
              </Box>
              <Stack direction="row" spacing={4} mx={10} mb={6} justifyContent="flex-end">
                <Button
                  onClick={() => {
                    setOpenDialog(false);
                  }}
                  variant="text"
                  label={t("BUTTON-CANCEL")}
                  color="secondary"
                  name="resetSearch"
                />
                <Button label={t("BUTTON-UPDATE")} type="submit" name="search" variant="text" disabled={hasHQSelected} />
              </Stack>
            </form>
          </Box>
        </Dialog>
      )}
      <ModalLocationExports open={openExport} exportLocations={exportLocations} handleClose={handleCloseExp} />
    </>
  );
}
