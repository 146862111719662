import { Roles } from "models";
import { PrivateRoutes } from "models/routes";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { AppStore } from "redux/store";

export default function NonAdminGuard() {
  const userState = useSelector((store: AppStore) => store.user);
  const role = userState.roles;

  return role === Roles.generalViewOnly || role === Roles.superAdmin ? <Navigate to={PrivateRoutes.ORGANIZATIONS} /> : <Outlet />;
}
