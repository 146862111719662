//** Components imports */
import { LocationListContextProvider } from "pages/locations";
import { OrganizationInfoView } from "./components";
//** UTILS */
import { OrganizationInfoContextProvider } from "./context";

export default function OrganizationsInformation() {
  return (
    <OrganizationInfoContextProvider>
      <LocationListContextProvider>
        <OrganizationInfoView />
      </LocationListContextProvider>
    </OrganizationInfoContextProvider>
  );
}
