/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-useless-fragment */
//** MUI Imports*/
import { Box, useMediaQuery, Stack, Theme, Typography, useTheme, Checkbox } from "@mui/material";
//** Components Imports*/
import {
  FilterChips,
  Input,
  InputApiCall,
  InputApiCallCheckboxWithOutSelectAll,
  InputApiCallInfiniteScroll,
  Select,
  TableSearch,
} from "components";
import { RoleGuard } from "guards";
import { Roles } from "models";
//** Context */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
//** Hooks */
import { useTranslation } from "react-i18next";
import { ILocationsListDOM } from "pages/locations/models";
import { useGlobalContext } from "context/globalContext";
//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";

//**Icons */
import PlaceIcon from "@mui/icons-material/Place";
import Translations from "layouts/components/Translations";

import LocBulkActions from "pages/locations/components/organism/LocBulkActions/LocBulkActions";
import useGetSearchData from "../../../hooks/useGetSearchData";
import { useLocationListContext } from "../../../context";
import useLocationSearch from "../../../hooks/useLocationSearch";

interface ISearchChip {
  key: keyof ILocationsListDOM; //** Key on DOM  */
  label: string; //** Translaton from i18n */
  data: any; //** State value */
}

export default function AppListHeaderLanding({ onSearch, embebed }: { onSearch: Function; embebed?: boolean }) {
  //** Context */
  const { locationHasFilter } = useListAndSearchContext();

  const nameSearchFn = useLocationSearch().locNameSearch;
  const visibilitySearch = useLocationSearch().locVisibilitySearch;
  const marketSearch = useLocationSearch().locMarketSearch;
  const purposeSearch = useLocationSearch().locPurposesSearch;
  const organizationsearch = useLocationSearch().locOrganizationSearch;

  const { orgEditMode } = useGlobalContext();
  const { selectedClaim } = useLocationListContext();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const MUITheme = useTheme();
  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);

  const {
    getPurposes,
    getCountryData,
    getOrganizations,
    orgPage,
    hasNextPage,
    orgData,
    setOrgPage,
    loadingOrg,
    setOrgData,
    loadingCountry,
    loadingPurpose,
  } = useGetSearchData();

  const { latitudeClaimMap, longitudeClaimMap, setLatitudeClaimMap, setLongitudeClaimMap } = useLocationListContext();
  const { locationsMapListArray, setSelectedClaim } = useLocationListContext();
  const { t } = useTranslation();
  const optionsVisibility = ["Visible", "Hidden"];

  const numSelected = selectedClaim.length;
  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = locationsMapListArray.map((n) => ({ id: n.id, isHQ: n.isHeadQuarter }));
      setSelectedClaim(newSelected);
      return;
    }
    setSelectedClaim([]);
  };

  const rowCount = locationsMapListArray.length;

  //**Modal */

  //**Chips */
  const filterChips: ISearchChip[] = [
    {
      key: "name",
      label: "INPUT-NAME",
      data: nameSearchFn.value,
    },
    {
      key: "isVisible",
      label: "INPUT-VISIBILITY",
      data: visibilitySearch.value,
    },
    {
      key: "purposeCodes",
      label: "INPUT-PURPUSE",
      data: purposeSearch?.valueLabel,
    },
    {
      key: "organizationId",
      label: "GLOBAL-TITLE-ORGNAIZATION",
      data: organizationsearch?.valueLabel,
    },
    {
      key: "marketOrCountry",
      label: "INPUT-MARKET",
      data: marketSearch?.valueLabel,
    },
    {
      key: "coordinates",
      label: "INPUT-COORDINATES",
      data: latitudeClaimMap && longitudeClaimMap ? `${latitudeClaimMap},${longitudeClaimMap}` : "",
    },
  ];
  //** Button */
  //Filter Chips functions
  const handleDelete = (filterName: "name" | "isVisible" | "purposeCodes" | "organizationId" | "marketOrCountry" | "coordinates") => {
    if (filterName === "name") nameSearchFn.clearByKey();
    if (filterName === "isVisible") visibilitySearch.clearByKey();
    if (filterName === "purposeCodes") purposeSearch.clearByKey();
    if (filterName === "organizationId") organizationsearch.clearByKey();
    if (filterName === "marketOrCountry") marketSearch.clearByKey();
    if (filterName === "coordinates") {
      setLatitudeClaimMap(undefined);
      setLongitudeClaimMap(undefined);
    }
    onSearch();
  };
  const handleDeleteAll = () => {
    nameSearchFn.clearSearch();
    visibilitySearch.clearSearch();
    purposeSearch.clearSearch();
    organizationsearch.clearSearch();
    marketSearch.clearSearch();
    setLatitudeClaimMap(undefined);
    setLongitudeClaimMap(undefined);
    onSearch();
  };

  return (
    <Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" px={3} mt={3} mb={1}>
        <Box display="flex" alignItems="center" width="50%">
          <PlaceIcon sx={{ fontSize: 24, mr: 2 }} />
          <Typography
            variant={embebed ? "h1" : "h2"}
            fontWeight="700"
            borderBottom={`2px solid ${MUITheme.palette.text.primary}`}
            width="150px"
          >
            <Translations text="GLOBAL-TITLE-LOCATIONS" toUpperCase />
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" width="50%" pl={4}>
          {orgEditMode && (
            <RoleGuard
              authorizedRoles={[
                Roles.superAdmin,
                Roles.organizationEditor,
                Roles.organizationOwner,
                Roles.locationEditor,
                Roles.locationOwner,
              ]}
            >
              <>
                {!underMd && (
                  <>
                    <Box display="flex" alignItems="center" mt={2} ml={-4}>
                      <Box display="flex" alignItems="center">
                        <Checkbox
                          color="primary"
                          indeterminate={numSelected > 0 && numSelected < rowCount}
                          checked={rowCount > 0 && numSelected === rowCount}
                          onChange={onSelectAllClick}
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                        />
                      </Box>
                      <Box>
                        <LocBulkActions onSearch={onSearch} hasSelectedItems={selectedClaim.length > 0} />
                      </Box>
                    </Box>
                  </>
                )}
              </>
            </RoleGuard>
          )}
          <Box ml="auto" display="flex">
            <TableSearch
              noOptionsComponent={
                <Box>
                  <Stack direction={!underMd ? "row" : "column"} spacing={4} mb={4}>
                    <Input name="loc-name" {...nameSearchFn} label={`${t("INPUT-NAME")}`} fullWidth />
                    <Select
                      name="loc-visibility"
                      value={visibilitySearch.value || ""}
                      label={`${t("INPUT-VISIBILITY")}`}
                      setValue={visibilitySearch.onChange}
                      options={optionsVisibility}
                    />
                  </Stack>

                  <Stack direction={!underMd ? "row" : "column"} spacing={4} mb={4}>
                    <InputApiCallCheckboxWithOutSelectAll
                      setPropValue={purposeSearch.onChange as any}
                      apiCallFunction={getPurposes}
                      loading={loadingPurpose}
                      label={`${t("INPUT-PURPUSE")}`}
                      initialValue={
                        purposeSearch.valueLabel
                          ?.split(",")
                          .map((item: string, index: number) => ({ label: item, id: purposeSearch.valueId?.split(",")[index] })) || []
                      }
                    />

                    <InputApiCall
                      name="loc-market-search"
                      setPropValue={marketSearch.onChange}
                      apiCallFunction={getCountryData}
                      loading={loadingCountry}
                      label={`${t("INPUT-MARKET")}`}
                      externalValue={{ label: marketSearch.valueLabel, id: marketSearch.valueId } || null}
                    />
                  </Stack>
                  {!embebed && !userState?.organization?.id && (
                    <Box mb={4}>
                      <InputApiCallInfiniteScroll
                        initialValue={organizationsearch.valueLabel}
                        name="loc-organization-search"
                        setPropValue={organizationsearch.onChange}
                        changeWithInputText
                        apiCallFunction={getOrganizations}
                        loading={loadingOrg}
                        label={`${t("GLOBAL-TITLE-ORGNAIZATION")}`}
                        hasNextPage={hasNextPage}
                        page={orgPage}
                        options={orgData}
                        setPage={setOrgPage}
                        setOptions={setOrgData}
                        search
                      />
                    </Box>
                  )}
                </Box>
              }
              onReset={handleDeleteAll}
              onSubmit={(e: any) => {
                e.preventDefault();
                onSearch();
              }}
            />
          </Box>
        </Box>
      </Box>
      {locationHasFilter && (
        <Stack direction="row" spacing={4} alignItems="center" ml={4} mt={underMd ? 2 : 4} mb={underMd ? 2 : 4} pb={underMd ? 0 : 8}>
          <FilterChips filterChips={filterChips} handleDelete={handleDelete} handleDeleteAll={handleDeleteAll} />
        </Stack>
      )}
    </Box>
  );
}
