import { Box, Grid, Skeleton, Stack } from "@mui/material";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import EmptyTableCards from "pages/locations/components/molecules/emptyTableCards/EmptyTableCards";
import { EmptyListText } from "components";
import { useEmptyCardsLocationData } from "hooks";
import { useGetOrganizations } from "../../../hooks";
import TableResponsiveRow from "../TableResponsiveRow/TableResponsiveRow";
import { useOrganizationListContext } from "../../../context";
import { AppListHeader } from "../..";
import { OrgsSearchFields } from "../../../hooks/useOrganizationsSearch";

export default function OrgTableResponsive() {
  //** Context */
  const { organizationsPage, organizationHasFilter } = useListAndSearchContext();
  const { organizationsArray } = useOrganizationListContext();

  const { loading, hasNextPage, loadMoreOrganizations, onSearch } = useGetOrganizations(true);
  const { dataOrganizationsCards } = useEmptyCardsLocationData();
  //**Search */
  const { searchParamsOrgs } = useListAndSearchContext();
  const nameSearch = searchParamsOrgs.get(OrgsSearchFields.name);
  const urlSearch = searchParamsOrgs.get(OrgsSearchFields.url);
  const statusSearch = searchParamsOrgs.get(OrgsSearchFields.status);
  const [infiniteRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore:
      organizationsPage === 0
        ? () => {}
        : () => loadMoreOrganizations({ name: nameSearch, is_suspended: statusSearch, website_url: urlSearch, nowPage: organizationsPage }),
    rootMargin: "0px 0px 400px 0px",
  });
  const hasData = organizationHasFilter?.length === 0 ? organizationsArray.length !== 0 : true;

  return (
    <>
      <AppListHeader onSearch={onSearch} />
      {loading && organizationsArray.length === 0 ? (
        <Stack spacing={4}>
          {Array.from({ length: 12 }).map((_, i) => (
            <Skeleton key={i} width="100%" height={60} variant="rounded" />
          ))}
        </Stack>
      ) : (
        <Box maxHeight="calc(100vh - 280px)" overflow="scroll">
          {organizationsArray?.map((item, i) => (
            <TableResponsiveRow data={item} index={i} />
          ))}
          {(hasNextPage || loading) && <Skeleton width="100%" height={80} ref={infiniteRef} />}

          <Box display="flex" justifyContent="center">
            {!hasData && (
              <Grid container spacing={4} justifyContent="center" my={8} ml={0} width="95vw">
                {dataOrganizationsCards.map((item, index) => (
                  <EmptyTableCards
                    key={index}
                    data={dataOrganizationsCards[index]}
                    name="custom-radios-delivery"
                    gridProps={{ md: 4, sm: 12, xs: 12 }}
                  />
                ))}
              </Grid>
            )}
            {organizationHasFilter?.length > 0 && organizationsArray.length === 0 && <EmptyListText />}
          </Box>
        </Box>
      )}
    </>
  );
}
