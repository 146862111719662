//** MUI Imports */
import { Box, Card, Divider, Theme, Typography, useMediaQuery } from "@mui/material";
//** Components */
import { MapOrgView } from "pages/organizations/components/molecules/MapOrgView";
import Breadcrumbs, { IBreadcrumbs } from "components/atoms/Breadcrumbs/Breadcrumbs";
import { BasicInformation, OrganizationAddress, OrganizationContact } from "pages/organizations/components";
import { PrivateRoutes } from "models";
//** Hooks */
import { useTranslation } from "react-i18next";
//** Utils */
import { capitalize } from "utilities";
import { SectionsTitles } from "components";
import { ButtonCreateOrganization } from "../molecules";
import { BusinessOrg } from "../molecules/BusinessOrg";

export default function OrganizationCreateView() {
  //** Hooks */
  const { t } = useTranslation();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  // Data for breadcrums
  const breadcrumbs: IBreadcrumbs[] = [
    {
      url: `/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`,
      label: capitalize(`${PrivateRoutes.ORGANIZATIONS}`),
      index: 0,
    },
    {
      url: "",
      label: `${t("GLOBAL-TITLE-NEW-ORGANIZATION")}`,
      index: 1,
    },
  ];
  return (
    <Box mx={underMd ? 4 : 0} mb={underMd ? 12 : 0}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Card sx={{ py: 8, px: 6 }}>
        <SectionsTitles title={`${t("ORGANIZATION-DETAIL-TITLE")}`} subTitle={`${t("ORGANIZATION-DETAIL-SUBTITLE")}`} />
        <BasicInformation createView />
        <Typography variant="h2" sx={{ mt: 8, fontWeight: 600, letterSpacing: "0.18px" }} id="orgContactInfo">
          {t("ORG-CREATE-NEW-HQ")}
        </Typography>
        <MapOrgView />
        <OrganizationContact createView />
        <OrganizationAddress />
        <BusinessOrg />
        {/* <GoogleMapsMUI label="123" /> */}
        <Box mt={12} />
        <Divider />
        <ButtonCreateOrganization />
      </Card>
    </Box>
  );
}
