/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
import { useState } from "react";
import { Badge, Box, Checkbox, ListItem, ListItemAvatar, ListItemButton, Skeleton, Theme, Typography, useMediaQuery } from "@mui/material";
import { Image } from "components";
import { useTables } from "hooks";
import { IOrganizationListDOM } from "pages/organizations/models";
import { PrivateRoutes } from "models";
import { useNavigate } from "react-router-dom";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useOrganizationListContext } from "../../../context";

export default function TableResponsiveRow({ data, index, onClick }: { data: IOrganizationListDOM; index: number; onClick?: () => void }) {
  const navigate = useNavigate();
  const { setLoad, load } = useOrganizationListContext();
  const { selected, organizationsArray } = useOrganizationListContext();
  const { orgActiveBulkActions } = useListAndSearchContext();
  const { setSelected } = useOrganizationListContext();
  const { handleClickTableCheckbox, isSelected } = useTables(setSelected, selected, organizationsArray);
  const [dobleClickId, setDobleClickId] = useState<string>("");
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const isItemSelected = isSelected(data.id);
  //Table logic to navigate
  const handleNavigate = (id: string) => {
    navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}/${id}`, { replace: false });
  };

  return (
    <ListItem sx={{ px: 0, py: 0 }}>
      <ListItemButton
        role={undefined}
        onClick={() => {
          if (orgActiveBulkActions) {
            return handleClickTableCheckbox(null as any, data.id);
          }
          if (onClick && dobleClickId !== data.id) {
            onClick();
            //** Activates redirect when doble click the row */
            setDobleClickId(data.id);
          } else handleNavigate(data.id);
        }}
        sx={{
          px: 0,
          backgroundColor: index % 2 === 0 ? "background.paper" : "background.default",
          display: "flex",
          alignItems: "center",
          height: !underMd ? "100px" : "auto",
          py: underMd ? 4 : 0,
        }}
      >
        <ListItemAvatar sx={{ mr: 8 }}>
          <Box display="flex">
            <Box display="flex" onClick={(e) => e.stopPropagation()} pl={orgActiveBulkActions ? 0 : 8}>
              {orgActiveBulkActions && (
                <Checkbox
                  onClick={(event) => handleClickTableCheckbox(event, data.id)}
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{
                    "aria-labelledby": data.id,
                  }}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, py: 0, mt: -1, px: 2 }}
                />
              )}
            </Box>

            <Box mt={0.4}>
              <Image
                src={typeof data.logo === "string" ? data?.logo : ""}
                alt={`logo-${data.id}`}
                name={`logo-${data.id}`}
                onLoad={(e: any) => {
                  if (e.type === "load") {
                    setLoad((prev: any) => {
                      const copy = prev;
                      copy[index] = false;
                      return [...copy];
                    });
                  }
                }}
                style={{
                  objectFit: "fill",
                  width: !underMd ? "120px" : "80px",
                  maxWidth: !underMd ? "120px" : "80px",
                  maxHeight: !underMd ? "60px" : "40px",
                  display: load[index] ? "none" : "block",
                }}
              />
            </Box>

            <Skeleton
              sx={{ display: load[index] ? "block" : "none" }}
              animation="wave"
              variant="rectangular"
              width={!underMd ? "120px" : "80px"}
              height={!underMd ? "60px" : "40px"}
            />
          </Box>
        </ListItemAvatar>
        <Box width={orgActiveBulkActions ? "calc(100% - 205px)" : "calc(100% - 160px)"} pr={orgActiveBulkActions ? 0 : 12.4}>
          <Typography variant="h3" fontWeight="700" mb={2} sx={{ display: "flex", justifyContent: "space-between" }}>
            {data.name.toUpperCase()}

            <Badge
              color={data.isSuspended ? "secondary" : "success"}
              badgeContent=" "
              sx={{
                "& .MuiBadge-badge": {
                  top: 9.5,
                  boxShadow: (theme) => `0 0 0 2px ${theme.palette.background.paper}`,

                  height: 14,
                  minWidth: 14,
                },
                fontSize: "10px",
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Box height="10px" width="100%" justifyContent="flex-end" />
            </Badge>
          </Typography>
          <Typography variant="h3" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "90%" }}>
            {data.hqInformation.website || "------"}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
