/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable prefer-object-spread */
import { useEffect, useRef, useState } from "react";
import { Box, Fade, CircularProgress, useMediaQuery, Theme } from "@mui/material";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import settings from "config/settings.json";
import { useLocationListContext } from "pages";
import Marker from "./Marker";
import { SearchMoreMapButton } from "./SearchMoreMapButton";

export interface ICoordinatesDashboard {
  lat: number;
  lng: number;
  id: string;
  name: string;
  status: boolean;
  address: string;
}
interface IMaps {
  positions: ICoordinatesDashboard[];
  mapHeight?: string;
  options?: google.maps.MapOptions | undefined;
  loading?: boolean;
  isListShow?: boolean;
  isLocationList?: boolean;
}
function MapDashboard({ positions, mapHeight, options, loading, isListShow, isLocationList }: IMaps) {
  //** Maps init */
  const [libraries] = useState<any>(["geometry", "drawing", "places"]);
  const matches = useMediaQuery("(max-width:1200px)");
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  //**Context */
  const { setLatitudeClaimMap, setLongitudeClaimMap, activeLocationItemList, setToggleChangeCoordinates } = useLocationListContext();

  const mapRef = useRef<google.maps.Map>();
  const [mapref, setMapRef] = useState<google.maps.Map>();
  const [hasCoordinates, setHasCoordinates] = useState(false);
  const [internalCoordinates, setInternalCoordinates] = useState<[number | undefined, number | undefined] | []>([]);

  const handleLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    setMapRef(map);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${settings.GOOGLE_API_KEY}`,
    libraries,
  });

  const mapStyles = {
    minHeight: mapHeight || "60vh",
    height: "100%",
    width: "100%",
  };
  const center = {
    lat: internalCoordinates[0] as any,
    lng: internalCoordinates[1] as any,
  };

  const mackerOnChange = {
    lat: activeLocationItemList ? +activeLocationItemList?.coordinates[0] : undefined,
    lng: activeLocationItemList ? +activeLocationItemList?.coordinates[1] : undefined,
  };

  useEffect(() => {
    if (mapRef.current) {
      //** When I select a marker  the center is on this one*/
      if (internalCoordinates[0] && internalCoordinates[1]) {
        //** Coordinates when moving the map are of the highest priority*/
        mapRef.current.panTo({ lat: internalCoordinates[0], lng: internalCoordinates[1] });
      } else if (activeLocationItemList && activeLocationItemList?.coordinates?.length > 1) mapRef.current.panTo(mackerOnChange as any);
      else if (positions.length > 0) mapRef.current.panTo({ lat: +positions[0].lat, lng: +positions[0].lng });
    }
  }, [internalCoordinates, positions, activeLocationItemList]);

  const getCurrentMapData = () => {
    if (!mapref) return;
    if (loading) return;
    const newCenter = mapref.getCenter();
    // const newZoom = mapref.getZoom();
    setLatitudeClaimMap(newCenter?.lat() !== 0 ? newCenter?.lat() : undefined);
    setLongitudeClaimMap(newCenter?.lng() !== 0 ? newCenter?.lng() : undefined);
    setToggleChangeCoordinates((value) => !value);
  };
  const getInternalMapData = () => {
    setHasCoordinates(true);
    if (!mapref) return;
    if (loading) return;
    const newCenter = mapref.getCenter();
    // const newZoom = mapref.getZoom();
    const latitude = newCenter?.lat() !== 0 ? newCenter?.lat() : undefined;
    const longitude = newCenter?.lng() !== 0 ? newCenter?.lng() : undefined;

    setInternalCoordinates([latitude, longitude]);
  };

  const locationOnMapListView = isListShow ? (matches ? "32" : "49") : matches ? "32" : "34";
  const locationOnClaimListView = isListShow ? (matches ? "32" : "47") : matches ? "32" : "33";

  const responsiveOptions: google.maps.MapOptions | undefined = underMd
    ? {
        zoomControlOptions: {
          position: 4,
        },
        streetViewControlOptions: {
          position: 4,
        },
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: 10.0,
        },
      }
    : {};

  return (
    <Box>
      <Fade in={!hasCoordinates && loading}>
        <Box
          position="relative"
          top="50px"
          left="46%"
          zIndex={1000}
          width={100}
          height={40}
          sx={{ backgroundColor: "#FFFFFF", boxShadow: "0 6px 16px rgba(0, 0, 0, 0.12)" }}
          borderRadius={8}
        >
          <CircularProgress size={20} sx={{ position: "relative", top: "25%", left: "40%" }} />
        </Box>
      </Fade>

      {hasCoordinates && (
        <SearchMoreMapButton
          loading={!!loading}
          getCurrentMapData={getCurrentMapData}
          top={underMd ? "70px" : "50px"}
          left={isLocationList ? locationOnMapListView : locationOnClaimListView}
        />
      )}

      {isLoaded && (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={positions.length > 0 || internalCoordinates ? 9 : 4}
          center={center}
          options={{
            ...options,
            ...responsiveOptions,

            restriction: {
              latLngBounds: {
                north: 90,
                south: -90,
                east: 180,
                west: -180,
              },
              strictBounds: true,
            },
          }}
          onLoad={handleLoad}
          onMouseUp={getInternalMapData}
          onZoomChanged={getInternalMapData}
        >
          {positions?.map((item) => (
            <>
              {item.lat && item.lng && (
                <Marker
                  position={{ lat: +item.lat, lng: +item.lng }}
                  name={item.name}
                  address1={item.address}
                  isVisible={item.status}
                  id={item.id}
                />
              )}
            </>
          ))}
        </GoogleMap>
      )}
    </Box>
  );
}

export default MapDashboard;
