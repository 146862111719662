/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTables } from "hooks";

import { PrivateRoutes } from "models";
import { Chip, Image } from "components";
import { useNavigate } from "react-router-dom";
import { useUsersListContext } from "pages/users/pages/usersList/context";
import { IUsersListDOM } from "pages/users/models";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { useGlobalContext } from "context/globalContext";

const DEFAULT_IMG_URL =
  "https://cdn-facom.sbd-prod.com/EMEA/PRODUCT/IMAGES/HIRES/WARRANTY/GENERAL/2024/6638c74b103422930227bda4-logo.png?image=667c74b02cc170faad05638e";
export default function TableResponsiveRow({
  data,
  index,
  invertBg,
  mainPage,
  embebed,
}: {
  data: IUsersListDOM;
  index: number;
  alwaysEdit?: boolean;
  invertBg?: boolean;
  mainPage?: boolean;
  embebed?: boolean;
}) {
  const navigate = useNavigate();
  const { usersArray, selected, load, setLoad } = useUsersListContext();
  const { setSelected } = useUsersListContext();
  const { userEditMode } = useGlobalContext();
  const { handleClickTableCheckbox, isSelected } = useTables(setSelected, selected, usersArray);
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isItemSelected = isSelected(data.id);
  //Table logic to navigate
  const handleNavigate = (id: string) => {
    navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.INFORMATION_NAV}/${id}`, { replace: false });
  };
  const theme = useTheme();

  function stringAvatarOrgList(name: string, lastName: string, isActivate: boolean) {
    const successColor = theme.palette.mode === "dark" ? theme.palette.success.dark : theme.palette.success.light;
    const defaultColor = theme.palette.mode === "dark" ? theme.palette.secondary.dark : theme.palette.secondary.light;

    return {
      sx: {
        bgcolor: isActivate ? successColor : defaultColor,
        color: theme.palette.common.white,
        fontWeight: 700,
      },
      children: `${name?.split(" ")[0][0]?.toUpperCase() || ""}${lastName?.split(" ")[0][0]?.toUpperCase() || ""}`,
    };
  }

  return (
    <ListItem sx={{ px: 0, py: 0 }}>
      <ListItemButton
        role={undefined}
        onClick={() => {
          if (userEditMode) {
            return handleClickTableCheckbox(null as any, data.id);
          }
          handleNavigate(data.id);
        }}
        sx={{
          px: 0,
          backgroundColor:
            index % 2 === 0 ? `background.${invertBg ? "paper" : "default"}` : `background.${invertBg ? "default" : "paper"}`,
          display: "flex",
          alignItems: "flex-start",
          py: 4,
        }}
      >
        <ListItemAvatar sx={{ mr: 8, height: "100%", mt: 2 }}>
          <Box display="flex">
            <Box display="flex" onClick={(e) => e.stopPropagation()} pl={userEditMode ? 0 : 8}>
              {userEditMode && (
                <Checkbox
                  onClick={(event) => handleClickTableCheckbox(event, data.id)}
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{
                    "aria-labelledby": data.id,
                  }}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, py: 0 }}
                />
              )}
              <Avatar {...stringAvatarOrgList(`${data.firstName}`, `${data.lastName}`, !data.isSuspended)} />
            </Box>
          </Box>
        </ListItemAvatar>
        <Box width={userEditMode ? "calc(100% - 120px)" : "100%"} pr={userEditMode ? 0 : 7.2} display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="flex-start" flexDirection="column">
            <Typography
              variant="h3"
              fontWeight="700"
              mb={2}
              mt={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
              alignItems="center"
            >
              {`${data.firstName} ${data.lastName}`.toUpperCase()}
            </Typography>
            {data?.locationData?.name && (
              <Typography variant="h3" mb={2} sx={{ display: "flex", justifyContent: "space-between" }} alignItems="center">
                {`${data.locationData.name || ""}`}
              </Typography>
            )}
            {data.title && (
              <Box display="flex" alignItems="center" flexWrap="wrap" gap={2} mt={2}>
                <Chip label={data.title.toUpperCase() || ""} size="small" fontSize={underMd ? 10 : 12} />
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            {embebed && (
              <Box mr={underMd ? 2 : 16}>
                <Image
                  src={typeof data?.organizationData[0]?.logo === "string" ? data?.organizationData[0]?.logo : DEFAULT_IMG_URL}
                  alt={`logo-${data.id}`}
                  name={`logo-${data.id}`}
                  onLoad={(e: any) => {
                    if (e.type === "load") {
                      setLoad((prev: any) => {
                        const copy = prev;
                        copy[index] = false;
                        return [...copy];
                      });
                    }
                  }}
                  style={{ objectFit: "fill", width: "80px", display: load[index] ? "none" : "block" }}
                />
                <Skeleton sx={{ display: load[index] ? "block" : "none" }} animation="wave" variant="rectangular" width={80} height={30} />
              </Box>
            )}
            <Box
              display="flex"
              flexDirection={mainPage ? "row" : "column"}
              alignContent="center"
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <IconButton
                onClick={(e: any) => {
                  e.stopPropagation();
                  window.location.href = `tel:${data.phone}`;
                }}
                sx={{ color: (theme: Theme) => theme.palette.text.primary, py: 2, px: 2 }}
              >
                <LocalPhoneIcon sx={{ fontSize: mainPage ? 24 : 22, pb: 0 }} />
              </IconButton>
              <IconButton
                onClick={(e: any) => {
                  e.stopPropagation();
                  window.location.href = `mailto:${data.username}`;
                }}
                sx={{ color: (theme: Theme) => theme.palette.text.primary, py: 2, px: 2 }}
              >
                <AlternateEmailIcon sx={{ fontSize: mainPage ? 24 : 22 }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
