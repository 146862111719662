//** MUI Imports*/
import { Box, Typography, useMediaQuery, Stack, Theme, IconButton, Checkbox } from "@mui/material";
//** Components Imports*/
import { FilterChips, Input, Select, TableSearch } from "components";
import Translations from "layouts/components/Translations";
import { RoleGuard } from "guards";
import { Roles } from "models";
import OrgBulkActions from "pages/organizations/components/organism/OrgBulkActions/OrgBulkActions";
//** Context */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
//** Hooks */
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { useOrganizationListContext } from "../../../context";
import useOrganizationsSearch, { OrgsSearchFields } from "../../../hooks/useOrganizationsSearch";

export default function AppListHeader({ onSearch }: { onSearch: Function }) {
  //** Context */
  const { setOrgActiveBulkActions, orgActiveBulkActions, organizationHasFilter } = useListAndSearchContext();
  const { organizationsArray, selected, setSelected } = useOrganizationListContext();

  const nameSearchFn = useOrganizationsSearch(OrgsSearchFields.name).orgNameSearch;
  const urlSearchFn = useOrganizationsSearch(OrgsSearchFields.url).orgUrlSearch;
  const statusSearch = useOrganizationsSearch(OrgsSearchFields.status).orgStatusSearch;
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { t } = useTranslation();

  const optionsStatus = [t("OPTION-SELECT-1"), t("OPTION-SELECT-2")];

  //**Chips */
  const filterChips = [
    {
      key: "name",
      label: "INPUT-NAME",
      data: nameSearchFn.value,
    },
    {
      key: "url",
      label: "INPUT-URL",
      data: urlSearchFn.value,
    },
    {
      key: "status",
      label: "INPUT-STATUS",
      data: statusSearch.value,
    },
  ];
  //Filter Chips functions
  const handleDelete = (filterName: "name" | "url" | "status") => {
    if (filterName === "name") nameSearchFn.clearByKey();
    if (filterName === "url") urlSearchFn.clearByKey();
    if (filterName === "status") statusSearch.clearByKey();
    onSearch();
  };
  const handleDeleteAll = () => {
    nameSearchFn.clearSearch();
    urlSearchFn.clearSearch();
    statusSearch.clearSearch();
    onSearch();
  };

  const numSelected = selected.length;
  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = organizationsArray.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const rowCount = organizationsArray.length;

  const clearSearch = () => {
    nameSearchFn.clearSearch();
    urlSearchFn.clearSearch();
    statusSearch.clearSearch();
    onSearch();
  };

  return (
    <Box sx={{ backgroundColor: underMd ? "background.default" : "background.paper" }} display="flex" alignItems="center" pb={4} pt={8}>
      <Box width={underMd ? "100%" : "70%"}>
        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={4} px={3} mb={1} alignItems="flex-start">
          <Box>
            <Box ml={2} mt={2}>
              <Typography variant="h1" fontWeight="700">
                <Box display="flex" alignItems="center" onClick={() => {}}>
                  <CorporateFareIcon sx={{ fontSize: 24, mr: 4 }} />
                  <Translations text="GLOBAL-TITLE-ALL-ORGANIZATIONS" />
                </Box>
              </Typography>
              {orgActiveBulkActions && (
                <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgOwner]}>
                  <Box display="flex" alignItems="center" ml={-4} mt={4}>
                    <Checkbox
                      color="primary"
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={onSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                    />

                    <Box display="flex" justifyContent="space-between">
                      <OrgBulkActions onSearch={onSearch} hasSelectedItems={selected.length > 0} />
                    </Box>
                  </Box>
                </RoleGuard>
              )}
            </Box>
          </Box>

          <Box
            ml="auto"
            display="flex"
            flexWrap="wrap"
            flexDirection={underMd ? "column" : "row"}
            alignItems={underMd ? "flex-end" : "normal"}
          >
            {orgActiveBulkActions ? (
              <IconButton
                sx={{ mr: 4 }}
                onClick={() => {
                  setOrgActiveBulkActions(false);
                  setSelected([]);
                }}
              >
                <CheckCircleIcon sx={{ fontSize: 20 }} />
              </IconButton>
            ) : (
              <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgOwner]}>
                <IconButton
                  sx={{ mr: 4 }}
                  onClick={() => {
                    setOrgActiveBulkActions(true);
                  }}
                >
                  <EditIcon sx={{ fontSize: 20 }} />
                </IconButton>
              </RoleGuard>
            )}

            <Box mt={underMd ? 4 : 0}>
              <TableSearch
                noOptionsComponent={
                  <Box>
                    <Stack direction={!underMd ? "row" : "column"} spacing={4} mb={4}>
                      <Input name="search-name" {...nameSearchFn} label={`${t("INPUT-NAME")}`} fullWidth />
                      <Select
                        name="search-status"
                        value={`${statusSearch.value || ""}`}
                        label={`${t("INPUT-STATUS")}`}
                        setValue={statusSearch.onChange}
                        options={optionsStatus}
                        fullWidth
                      />
                    </Stack>
                    <Input name="org-url-search" {...urlSearchFn} label={`${t("INPUT-URL")}`} fullWidth />
                  </Box>
                }
                onReset={clearSearch}
                onSubmit={(e: any) => {
                  e.preventDefault();
                  onSearch();
                }}
              />
            </Box>
          </Box>
        </Box>
        {organizationHasFilter && (
          <Stack direction="row" spacing={4} alignItems="center" ml={4} mt={6} mb={selected.length > 0 && orgActiveBulkActions ? 2 : 12}>
            <FilterChips filterChips={filterChips} handleDelete={handleDelete} handleDeleteAll={handleDeleteAll} />
          </Stack>
        )}
      </Box>
    </Box>
  );
}
