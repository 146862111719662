import { useFetchAndLoad, useToastMui } from "hooks";
import { IMapsAdditionalItemFromAPI } from "pages/locations/models";
import { googleMapsAdditionalDataAPI } from "pages/locations/services";
import { hasHttp } from "utilities";
import { getLocationsGoogleAPIInformation } from "services";
import { useTranslation } from "react-i18next";
import { useOrganizationContext } from "../context";

export default function useSetGoogleMapDataOrg() {
  const { handleCreateToast } = useToastMui();
  const { orgSelectedMark } = useOrganizationContext();
  const { t } = useTranslation();
  const {
    setPhoneOrganization,
    setUrl,
    setAddress1Organization,
    setCityOrganization,
    setPostalCodeOrganization,
    setLatOrganization,
    setLongOrganization,
    setCountryOrganization,
    setGoogleMapsOrgInputs,
    setStateOrganization,
    setRegionOrganization,
    setInitalUrl,
    setMapId,
  } = useOrganizationContext();
  const { callEndpoint, loading } = useFetchAndLoad();

  //For location information google send a array with information split on array with codes
  //here we take those codes for select the data that we need
  let postalCodeGoogle = "";
  let cityGoogle = "";
  let marketGoogle = "";
  let stateGoogle = "";

  const handleClickScroll = () => {
    if (!orgSelectedMark) return;
    const inputElement = document.getElementById("orgContactInfo");
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const setDataToCompareGM = (additionalData: any, data: any) => {
    setGoogleMapsOrgInputs({
      address1: data[0]?.formatted_address,
      market: marketGoogle,
      city: cityGoogle,
      state: stateGoogle,
      region: additionalData.region,
      postalCode: postalCodeGoogle,
      coordinates_latitude: `${additionalData.coordinates[0]}`,
      coordinates_longitude: `${additionalData.coordinates[1]}`,
      phone: additionalData.phone,
      // eslint-disable-next-line no-nested-ternary
      website: additionalData.website,
    });
  };

  const getGMDataForInfoView = async (response: any) => {
    //Get google api response
    const data = response?.data?.results;
    const responseAdd = await callEndpoint(googleMapsAdditionalDataAPI({ mapId: data[0]?.place_id }));
    const additionalData: IMapsAdditionalItemFromAPI = responseAdd?.data?.result;

    // eslint-disable-next-line no-plusplus
    data[0]?.address_components.forEach((addressComponent: any) => {
      if (addressComponent.types.includes("postal_code")) {
        postalCodeGoogle = addressComponent?.long_name;
      }
      if (addressComponent.types.includes("locality")) {
        cityGoogle = addressComponent?.long_name;
      }
      if (addressComponent.types.includes("country")) {
        marketGoogle = addressComponent?.short_name;
      }
      if (addressComponent.types.includes("administrative_area_level_1")) {
        stateGoogle = addressComponent?.long_name;
      }
    });
    //Set google maps inputs
    setDataToCompareGM(additionalData, data);
  };

  const onPlaceChanged = async (response: any) => {
    //Get google api response
    const data = response?.data?.results;
    const responseAdd = await callEndpoint(googleMapsAdditionalDataAPI({ mapId: data[0]?.place_id }));
    const additionalData: IMapsAdditionalItemFromAPI = responseAdd?.data?.result;
    //Get data from google api
    setMapId(data[0]?.place_id);
    setAddress1Organization(data[0]?.formatted_address);
    setRegionOrganization({ label: additionalData.region, id: additionalData.region });
    setLatOrganization(`${additionalData.coordinates[0]}`);
    setLongOrganization(`${additionalData.coordinates[1]}`);

    // eslint-disable-next-line no-plusplus
    data[0]?.address_components.forEach((addressComponent: any) => {
      if (addressComponent.types.includes("postal_code")) {
        setPostalCodeOrganization(addressComponent?.long_name);
        postalCodeGoogle = addressComponent?.long_name;
      }
      if (addressComponent.types.includes("locality")) {
        setCityOrganization(addressComponent?.long_name);
        cityGoogle = addressComponent?.long_name;
      }
      if (addressComponent.types.includes("country")) {
        setCountryOrganization({ label: addressComponent?.short_name, id: addressComponent?.short_name });
        marketGoogle = addressComponent?.short_name;
      }
      if (addressComponent.types.includes("administrative_area_level_1")) {
        setStateOrganization(addressComponent?.long_name);
        stateGoogle = addressComponent?.long_name;
      }
    });

    //Additional data
    setPhoneOrganization(additionalData.phone);
    setUrl(hasHttp(additionalData.website));
    setInitalUrl(additionalData.website.includes("https") ? "https://" : "http://");
    //Set google maps inputs
    setDataToCompareGM(additionalData, data);
    handleClickScroll();
    // getGMDataForInfoView(response);
  };

  const getMapsData = async (placeId: string) => {
    try {
      const data: any = await getLocationsGoogleAPIInformation(placeId);
      onPlaceChanged(data);
    } catch (e) {
      console.error(e);
      handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error");
    }
  };

  return { getGMDataForInfoView, onPlaceChanged, getMapsData, loading };
}
