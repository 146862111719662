/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-useless-fragment */
import { useState } from "react";
//** MUI Imports*/
import { Box, useMediaQuery, Stack, Theme, IconButton } from "@mui/material";
//** Components Imports*/
import {
  FilterChips,
  Input,
  InputApiCall,
  InputApiCallCheckboxWithOutSelectAll,
  InputApiCallInfiniteScroll,
  Select,
  TableSearch,
} from "components";

//** Context */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
//** Hooks */
import { useTranslation } from "react-i18next";
import { ILocationsListDOM } from "pages/locations/models";
import { useGlobalContext } from "context/globalContext";
//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";

//**Icons */
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { RoleGuard } from "guards";
import { Roles } from "models";
import useGetSearchData from "../../../hooks/useGetSearchData";
import { useLocationListContext } from "../../../context";
import LocationImportOnHeader from "./LocationImportOnHeader";
import TextHeaderLoc from "./TextHeaderLoc";
import OrganizationEditButtonResp from "./OrganizationEditButtonResp";
import useLocationSearch from "../../../hooks/useLocationSearch";

interface ISearchChip {
  key: keyof ILocationsListDOM; //** Key on DOM  */
  label: string; //** Translaton from i18n */
  data: any; //** State value */
}

export default function AppListHeader({
  onSearch,
  embebed,
  mainPage,
  loading,
  fromOrg,
  hiddeEdit,
}: {
  onSearch: Function;
  embebed?: boolean;
  mainPage?: boolean;
  loading?: boolean;
  fromOrg?: boolean;
  hiddeEdit?: boolean;
}) {
  //** Context */
  const { locationHasFilter } = useListAndSearchContext();
  const { setSelectedClaim } = useLocationListContext();

  const nameSearchFn = useLocationSearch().locNameSearch;
  const visibilitySearch = useLocationSearch().locVisibilitySearch;
  const marketSearch = useLocationSearch().locMarketSearch;
  const purposeSearch = useLocationSearch().locPurposesSearch;
  const organizationsearch = useLocationSearch().locOrganizationSearch;

  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);

  const {
    getPurposes,
    getCountryData,
    getOrganizations,
    orgPage,
    hasNextPage,
    orgData,
    setOrgPage,
    loadingOrg,
    setOrgData,
    loadingCountry,
    loadingPurpose,
  } = useGetSearchData();

  const { locEditMode, setLocEditMode } = useGlobalContext();

  const { latitudeClaimMap, longitudeClaimMap, setLatitudeClaimMap, setLongitudeClaimMap } = useLocationListContext();

  const { t } = useTranslation();
  const optionsVisibility = ["Visible", "Hidden"];

  const [callStatus, setCallStatus] = useState<"success" | "error" | null>(null);

  //**Modal */

  //**Chips */
  const filterChips: ISearchChip[] = [
    {
      key: "name",
      label: "INPUT-NAME",
      data: nameSearchFn.value,
    },
    {
      key: "isVisible",
      label: "INPUT-VISIBILITY",
      data: visibilitySearch.value,
    },
    {
      key: "purposeCodes",
      label: "INPUT-PURPUSE",
      data: purposeSearch?.valueLabel,
    },
    {
      key: "organizationId",
      label: "GLOBAL-TITLE-ORGNAIZATION",
      data: organizationsearch?.valueLabel,
    },
    {
      key: "marketOrCountry",
      label: "INPUT-MARKET",
      data: marketSearch?.valueLabel,
    },
    {
      key: "coordinates",
      label: "INPUT-COORDINATES",
      data: latitudeClaimMap && longitudeClaimMap ? `${latitudeClaimMap},${longitudeClaimMap}` : "",
    },
  ];
  //Filter Chips functions
  const handleDelete = (filterName: "name" | "isVisible" | "purposeCodes" | "organizationId" | "marketOrCountry" | "coordinates") => {
    if (filterName === "name") nameSearchFn.clearByKey();
    if (filterName === "isVisible") visibilitySearch.clearByKey();
    if (filterName === "purposeCodes") purposeSearch.clearByKey();
    if (filterName === "organizationId") organizationsearch.clearByKey();
    if (filterName === "marketOrCountry") marketSearch.clearByKey();
    if (filterName === "coordinates") {
      setLatitudeClaimMap(undefined);
      setLongitudeClaimMap(undefined);
    }
    onSearch();
  };

  const handleDeleteAll = () => {
    nameSearchFn.clearSearch();
    visibilitySearch.clearSearch();
    purposeSearch.clearSearch();
    organizationsearch.clearSearch();
    marketSearch.clearSearch();
    setLatitudeClaimMap(undefined);
    setLongitudeClaimMap(undefined);
    onSearch();
  };

  return (
    <Box
      sx={{ backgroundColor: underMd ? "background.default" : "background.paper" }}
      display="flex"
      alignItems="center"
      pb={4}
      pt={embebed && underMd ? 4 : 8}
    >
      <Box width={underMd ? "100%" : "70%"}>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          gap={4}
          px={3}
          mb={1}
          alignItems={underMd && embebed ? "center" : "flex-start"}
        >
          <TextHeaderLoc mainPage={mainPage} loading={loading} onSearch={onSearch} embebed={embebed} fromOrg={fromOrg} />

          <Box
            ml="auto"
            display="flex"
            flexWrap="wrap"
            flexDirection={underMd ? "column" : "row"}
            alignItems={underMd ? "flex-end" : "normal"}
          >
            {!fromOrg && !hiddeEdit && (
              <>
                {locEditMode ? (
                  <IconButton
                    sx={{ mr: 4 }}
                    onClick={() => {
                      setLocEditMode(false);
                      setSelectedClaim([]);
                    }}
                  >
                    <CheckCircleIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                ) : (
                  <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgOwner, Roles.organizationOwner, Roles.organizationEditor]}>
                    <IconButton
                      sx={{ mr: 4 }}
                      onClick={() => {
                        setLocEditMode(true);
                      }}
                    >
                      <EditIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </RoleGuard>
                )}
              </>
            )}
            {underMd && !mainPage && <OrganizationEditButtonResp />}
            {!fromOrg && (
              <TableSearch
                noOptionsComponent={
                  <Box>
                    <Stack direction={!underMd ? "row" : "column"} spacing={4} mb={4}>
                      <Input name="loc-name" {...nameSearchFn} label={`${t("INPUT-NAME")}`} fullWidth />
                      <Select
                        name="loc-visibility"
                        value={visibilitySearch.value || ""}
                        label={`${t("INPUT-VISIBILITY")}`}
                        setValue={visibilitySearch.onChange}
                        options={optionsVisibility}
                      />
                    </Stack>

                    <Stack direction={!underMd ? "row" : "column"} spacing={4} mb={4}>
                      <InputApiCallCheckboxWithOutSelectAll
                        setPropValue={purposeSearch.onChange as any}
                        apiCallFunction={getPurposes}
                        loading={loadingPurpose}
                        label={`${t("INPUT-PURPUSE")}`}
                        initialValue={
                          purposeSearch?.valueLabel
                            ? purposeSearch?.valueLabel
                                ?.split(",")
                                .map((item: string, index: number) => ({ label: item, id: purposeSearch.valueId?.split(",")[index] }))
                            : []
                        }
                      />

                      <InputApiCall
                        name="loc-market-search"
                        setPropValue={marketSearch.onChange}
                        apiCallFunction={getCountryData}
                        loading={loadingCountry}
                        label={`${t("INPUT-MARKET")}`}
                        externalValue={{ label: marketSearch.valueLabel || "", id: marketSearch.valueId || "" } || null}
                      />
                    </Stack>
                    {!embebed && !userState?.organization?.id && (
                      <Box mb={4}>
                        <InputApiCallInfiniteScroll
                          initialValue={{ label: organizationsearch.valueLabel || "", id: organizationsearch.valueId || "" } || null}
                          name="loc-organization-search"
                          setPropValue={organizationsearch.onChange}
                          changeWithInputText
                          apiCallFunction={getOrganizations}
                          loading={loadingOrg}
                          label={`${t("GLOBAL-TITLE-ORGNAIZATION")}`}
                          hasNextPage={hasNextPage}
                          page={orgPage}
                          options={orgData}
                          setPage={setOrgPage}
                          setOptions={setOrgData}
                          search
                        />
                      </Box>
                    )}
                  </Box>
                }
                onReset={handleDeleteAll}
                onSubmit={(e: any) => {
                  e.preventDefault();
                  onSearch();
                }}
              />
            )}
          </Box>
        </Box>
        {!!locationHasFilter && (
          <Stack direction="row" spacing={4} alignItems="center" ml={4} mt={underMd ? 2 : 4} mb={underMd ? 2 : 4} pb={underMd ? 0 : 8}>
            <FilterChips filterChips={filterChips} handleDelete={handleDelete} handleDeleteAll={handleDeleteAll} />
          </Stack>
        )}
        <LocationImportOnHeader callStatus={callStatus} setCallStatus={setCallStatus} />
      </Box>
    </Box>
  );
}
