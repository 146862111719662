import { useState } from "react";
//** MUI Imports */
import { IconButton, Stack, Theme, useMediaQuery } from "@mui/material";
//** Components */
import { Button, ModalConfirm } from "components";
import { PrivateRoutes, Roles } from "models";
import { RoleGuard } from "guards";
//**Hooks */

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchAndLoad, useRoleGuard, useToastMui } from "hooks";
import useGetPictures from "hooks/useGetPictures";
//**Icons */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
//**Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";

import { useDeleteOrganizations } from "pages/organizations/hooks";
//**Context */
import { useOrganizationContext } from "pages/organizations/context";
import isUrl from "is-url";

import { capitalize } from "utilities";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useGlobalContext } from "context/globalContext";
//**SERVICES */
import { updateLocationAPI, useUpdateLocation } from "pages/locations";
import { useUpdateUsers } from "pages/users/components/molecules/UpdateUserButtons/hooks";
import { IUpdateBodyLocationAPI } from "pages/locations/services/api.interfaces";
import { IUpdateBodyOrganization, updateOrganizationsAPI } from "../../../services";
import { useOrganizationInfoContext } from "../../../context";

export default function ButtonsInformation({ orgLandingEmbebed }: { orgLandingEmbebed?: boolean }) {
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const { setOrgEditMode, setUserEditMode, setLocEditMode } = useGlobalContext();
  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);
  const { organization } = userState;

  //**Hooks */
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { callEndpoint, loading } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { handleGetCompressedBase64 } = useGetPictures();
  const { id } = useParams();

  const authUpdateOrganization = useRoleGuard([Roles.superAdmin, Roles.multiOrgOwner, Roles.organizationOwner, Roles.organizationEditor]);
  //**Context */
  const { active, frecuency, dataCopy } = useOrganizationInfoContext();
  const { name, description, logo, url, initalUrl, isValidEmail, newHqData } = useOrganizationContext();
  const { phoneOrganization, emailOrganization, locHqId, primaryContact } = useOrganizationContext();
  const { setSearchToggleDash } = useListAndSearchContext();
  const { deleteOrganizations } = useDeleteOrganizations();
  const { updateHqLocation } = useUpdateLocation();
  const { updateUserPrimaryContact } = useUpdateUsers();

  const updateOrganization = async () => {
    let body: IUpdateBodyOrganization = {
      description,
      is_suspended: active,
      name,
      notification_time: +`${frecuency?.value}`,
    };

    const bodyLocation: Partial<IUpdateBodyLocationAPI> = {
      body: {
        contact: {
          website: `${initalUrl}${url}`,
          email: emailOrganization || "",
          phone: phoneOrganization,
        },
        organization_id: `${userState.organization?.id ? userState.organization?.id : id}`,
      },
    };

    //** If the image change we need to update to the new compressed image */
    if ((dataCopy?.logo?.length ? dataCopy?.logo[0] : null) !== logo[0]) {
      const fileCompressed: string = await handleGetCompressedBase64(logo, 0);
      body = { ...body, logo: fileCompressed };
    }

    if (newHqData?.id && dataCopy.hqId !== newHqData?.id) await updateHqLocation(id || "", newHqData?.id);

    if (primaryContact?.id) await updateUserPrimaryContact(primaryContact?.id || "", id || "", "Organization");

    await callEndpoint(updateOrganizationsAPI(id ? `${id}` : `${organization?.id}`, { body }), (error: any) =>
      handleCreateToast(`${t("ALERT-ERROR-UPDATE")} : ${capitalize(error?.response?.data?.message)} `, "error"),
    );
    if (locHqId) {
      await callEndpoint(updateLocationAPI(locHqId, bodyLocation), (error: any) =>
        handleCreateToast(`${t("ALERT-ERROR-UPDATE")} : ${capitalize(error?.response?.data?.message)} `, "error"),
      );
    }
    setSearchToggleDash((prev: boolean) => !prev);

    handleCreateToast(t("ALERT-SUCCESS-UPDATE"), "success");
    setOrgEditMode(false);
    setUserEditMode(false);
    setLocEditMode(false);
  };

  const handleCloseDelete = () => setOpenModalDelete(false);

  const buttonIsDisabled =
    loading ||
    //Required fields
    !authUpdateOrganization ||
    !name ||
    logo.length === 0 ||
    (emailOrganization && emailOrganization?.length > 0 && !isValidEmail) ||
    (url.length > 0 && !isUrl(`${initalUrl}${url}`));

  // different layout on responsive view
  return (
    <>
      {underMd || orgLandingEmbebed ? (
        <IconButton
          onClick={() => {
            updateOrganization();
          }}
          disabled={buttonIsDisabled}
        >
          <CheckCircleIcon sx={{ fontSize: underMd ? 24 : 28 }} color="success" />
        </IconButton>
      ) : (
        <Stack direction="row" gap={4} mt={8}>
          <Button name="org-update" onClick={updateOrganization} label={t("BUTTON-UPDATE")} size="large" disabled={buttonIsDisabled} />
          <Button
            name="org-cancel-update"
            onClick={() => {
              navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`, { replace: false });
            }}
            label={t("BUTTON-CANCEL")}
            size="large"
            color="secondary"
            variant="outlined"
          />
          <RoleGuard authorizedRoles={[Roles.superAdmin]}>
            <Button
              name="org-delete"
              onClick={() => setOpenModalDelete(true)}
              label={t("BUTTON-DELETE")}
              size="large"
              variant="text"
              capitalize
            />
          </RoleGuard>
          <ModalConfirm
            open={openModalDelete}
            title={t("ORGANIZATIONS-DELETE-MODAL-CONFIRM-TITLE")}
            description={t("ORGANIZATIONS-DELETE-MODAL-CONFIRM-DESCRIPTION")}
            textButtonConfirm={t("BUTTON-ACCEPT")}
            handleClose={handleCloseDelete}
            handleConfirm={() => {
              deleteOrganizations([`${id}`]);
              setOpenModalDelete(false);
              navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`, { replace: false });
            }}
            sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
            sxTitle={{ fontSize: "2rem !important" }}
          />
        </Stack>
      )}
    </>
  );
}
