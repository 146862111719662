/* eslint-disable no-shadow */
import { useState } from "react";
//* MUI Imports
import { Box, Fade, Theme, useMediaQuery } from "@mui/material";
//* Redux
// ** Components
import { AppListHeader, LocationDashboardContent, LocationDashboardList } from "pages/locations";
//** Hooks */
import { useSettings } from "hooks";
import { useTheme } from "@emotion/react";
import { LocSpeedDial, MapSpeedDialLoc } from "components/responsiveComponents";
import { RoleGuard } from "guards";
import { Roles } from "models";
import { useGlobalContext } from "context/globalContext";

export default function LocationDashboardView({
  loading,
  hasNextPage,
  loadMoreLocations,
  onSearch,
  page,
  embebed,
  hiddeEdit,
}: {
  loading: boolean;
  hasNextPage: boolean;
  loadMoreLocations: () => void;
  onSearch: Function;
  page: number;
  embebed?: boolean;
  hiddeEdit?: boolean;
}) {
  //* States
  const [leftSidebarOpen, setLeftSidebarOpen] = useState<boolean>(false);
  const [permanentLeftBar, setPermanentLeftBar] = useState<boolean>(true);
  const { locEditMode } = useGlobalContext();
  // ** Hooks
  const theme = useTheme();
  const hidden = useMediaQuery("(min-width:1200px)");
  const { settings } = useSettings();
  // ** Vars
  const { skin } = settings;
  const smAbove = useMediaQuery("(min-width:1200px)"); //** Left bar over or half */
  // eslint-disable-next-line no-shadow
  const sidebarWidth = smAbove ? 450 : 300;
  const handleLeftSidebarToggle = () => setLeftSidebarOpen(!leftSidebarOpen);
  const handleChangeLeftBar = () => setPermanentLeftBar((prev: boolean) => !prev);
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <Fade in unmountOnExit timeout={500}>
      <Box>
        <Box
          sx={{
            pt: 3,
            px: 5,
            borderRadius: "1rem 1rem 0 0",
            // eslint-disable-next-line no-shadow
            backgroundColor: "background.paper",
          }}
        >
          <AppListHeader onSearch={onSearch} hiddeEdit={hiddeEdit} mainPage />
        </Box>

        <Box
          sx={{
            pt: 10,
            width: "100%",
            display: "flex",
            borderRadius: "0 0 1rem 1rem",
            overflow: "hidden",
            position: "relative",
            backgroundColor: "background.paper",
            boxShadow: skin === "bordered" ? 0 : 6,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            ...(skin === "bordered" && { border: `1px solid ${theme.palette.divider}` }),
          }}
        >
          <LocationDashboardList
            permanentLeftBar={smAbove ? permanentLeftBar : true}
            hidden={hidden}
            leftSidebarOpen={leftSidebarOpen}
            mdAbove={smAbove}
            sidebarWidth={sidebarWidth}
            handleLeftSidebarToggle={handleLeftSidebarToggle}
            loading={loading}
            hasNextPage={hasNextPage}
            page={page}
            loadMoreLocations={loadMoreLocations}
          />

          <LocationDashboardContent
            hidden={hidden}
            permanentLeftBar={permanentLeftBar}
            handleChangeLeftBar={handleChangeLeftBar}
            smAbove={smAbove}
            handleLeftSidebarToggle={handleLeftSidebarToggle}
            isListShow={permanentLeftBar}
            isLocationList
            loading={loading}
          />
        </Box>
        <RoleGuard authorizedRoles={[Roles.superAdmin]}>
          <Fade in={locEditMode && !underMd}>
            <Box position="relative">
              <LocSpeedDial absolute />
            </Box>
          </Fade>
        </RoleGuard>
        <Fade in={!locEditMode && !underMd && !embebed}>
          <Box position="relative">
            <MapSpeedDialLoc absolute />
          </Box>
        </Fade>
      </Box>
    </Fade>
  );
}
