/* eslint-disable @typescript-eslint/ban-ts-comment */
// ** MUI Imports
import MuiChip from "@mui/material/Chip";
// ** Third Party Imports
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from "clsx";
// ** Hooks Imports
import useBgColor, { UseBgColorType } from "core/hooks/useBgColor";
import { Theme, useMediaQuery, useTheme } from "@mui/material";
// ** Types
import { CustomChipProps } from "./types";

function Chip(props: CustomChipProps) {
  // ** Props
  const { sx, skin, color, rounded, disableTheme, variant, fontSize } = props;
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const defaultFontSize = fontSize || (underMd ? 12 : 14);
  const fontSizeSx = { ...sx, fontSize: defaultFontSize };

  // ** Hook
  const bgColors = useBgColor();
  const themeMUI = useTheme();
  const themeCondition = disableTheme ? skin === "light" : themeMUI.palette?.mode === "light" || skin === "light";

  const colors: UseBgColorType = {
    primary: { ...bgColors.primaryLight },
    secondary: { ...bgColors.secondaryLight },
    success: { ...bgColors.successLight },
    error: { ...bgColors.errorLight },
    warning: { ...bgColors.warningLight },
    info: { ...bgColors.infoLight },
  };

  const propsToPass = { ...props };

  propsToPass.rounded = undefined;

  return (
    <MuiChip
      {...propsToPass}
      variant={variant || "filled"}
      className={clsx({
        "MuiChip-rounded": rounded,
        "MuiChip-light": skin === "light",
      })}
      //@ts-ignore
      sx={themeCondition && color ? Object?.assign(colors[color] || {}, fontSizeSx || {}) : fontSizeSx}
    />
  );
}

export default Chip;
