import { getAllFromUrl, getOneFromUrl } from "utilities";
import { axios as http } from "frameworks_and_drivers/external_interfaces/Axios";
import config from "config/settings.json";
import setting from "../config/settings.json";
import { IRolesList } from "./globalServices.models";

export const getLocationsGoogleAPIInformation = async (placeId: string) => {
  const URL = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${setting.GOOGLE_API_KEY}`;
  const response = await http.get<any>(URL);
  return response;
};

export const getAllRolesAPI = (props?: any) => {
  return getAllFromUrl<IRolesList>("roles/get-all", props);
};
export const getFileByNameAPI = (name: string) => {
  return getOneFromUrl<unknown>(`file-storage/${name}`);
};

export const logOutFromSSO = () => {
  // eslint-disable-next-line max-len
  const loginRedirectURL = `${config.AUTH_URI}/idp/startSLO.ping?TargetResource=${config.redirectUri}`;
  window.location.href = loginRedirectURL;
};
