/* eslint-disable no-unused-vars */
import { MouseEvent, ReactNode } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TooltipApp } from "../tooltip";

export default function MenuMoreBulk({
  children,
  anchorEl,
  open,
  handleClick,
  handleClose,
}: {
  children: ReactNode;
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClick: (event: MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
}) {
  return (
    <div>
      <TooltipApp title="More options">
        <IconButton
          id="more-bulk-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon sx={{ fontSize: 24 }} />
        </IconButton>
      </TooltipApp>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {children}
      </Menu>
    </div>
  );
}
