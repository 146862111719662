import { Dispatch, SetStateAction } from "react";

export default function useTables(setSelected: Dispatch<SetStateAction<string[]>>, selected: string[], listArray: any[]) {
  const handleClickTableCheckbox = (event: React.MouseEvent<unknown> | null, id: string | number) => {
    const selectedIndex = selected.indexOf(`${id}`);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, `${id}`);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };
  const handleClickTableCheckboxMobile = (event: React.MouseEvent<unknown> | null, id: string | number) => {
    const selectedIndex = selected.indexOf(`${id}`);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, `${id}`);
      window.navigator.vibrate(150);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = listArray.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id: string | number) => selected.indexOf(`${id}`) !== -1;

  return { handleClickTableCheckbox, isSelected, handleSelectAllClick, handleClickTableCheckboxMobile };
}
