import { useEffect } from "react";
import { Box, Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetReduxUser } from "hooks";
import { UsersList } from "pages/users";
import { Maps } from "components";
import { useLocationContext } from "pages/locations/context";
import { LocationEditResponsive } from "pages/locations/pages/locationsInformation/components/responsive";
import { useGlobalContext } from "context/globalContext";
import { LocHeaderLanding } from "../../molecules";
import { LocAddressInfo } from "../../molecules/LocAddressInfo";
import { LocOpenHoursLanding } from "../../molecules/LocOpenHoursLanding";
import { BusinessLocLanding } from "../../molecules/BusinessLocLanding";
import { LocOwnerLanding } from "../../molecules/LocOwnerLanding";
import SBDConnectLanding from "../../molecules/SBDConnectLanding/SBDConnectLanding";

export default function LocRespLanding({ loading }: { loading?: boolean }) {
  const { id } = useParams();
  const { userState } = useGetReduxUser();
  const { latitude, longitude, name, isVisible, address1 } = useLocationContext();
  const { mapLocDetailView, setMapLocDetailView, setLocEditMode, locEditMode, setUserEditMode } = useGlobalContext();

  useEffect(() => {
    setMapLocDetailView(false);
    setLocEditMode(false);
    setUserEditMode(false);
  }, []);

  return (
    <Box>
      {locEditMode ? (
        <LocationEditResponsive />
      ) : (
        <>
          <LocHeaderLanding loading={loading} />
          {mapLocDetailView ? (
            <Maps
              positions={
                [
                  {
                    lat: +latitude || 180,
                    lng: +longitude || 0,
                    name,
                    status: isVisible,
                    address: address1,
                  },
                ] || []
              }
              singleDetail
              mapHeight="calc(100vh - 280px)"
            />
          ) : (
            <>
              <LocAddressInfo loading={loading} />
              <LocOpenHoursLanding loading={loading} />
              <BusinessLocLanding loading={loading} />
              {loading ? <Skeleton width="100%" height={100} variant="rounded" sx={{ mt: 4 }} /> : <SBDConnectLanding />}
              <LocOwnerLanding loading={loading} />
              <Box mt={-8} mb={20}>
                <UsersList locationId={id || ""} organizationId={userState.organization?.id || ""} embebed invertBgHeader showUserTitle />
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
}
