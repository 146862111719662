import { Box, useMediaQuery } from "@mui/material";
import { MapOrg } from "components/atoms/Map/MapOrg";
import { useOrganizationContext } from "pages/organizations/context";
import useMapOrgData from "pages/organizations/hooks/useMapOrgData";
import useSetGoogleMapDataOrg from "pages/organizations/hooks/useSetGoogleMapDataOrg";
import ClaimOrgSearch from "pages/organizations/components/molecules/claimSearch/ClaimOrgSearch";
import { useEffect } from "react";

import { Roles } from "models";
import useAutorizeUserByRole from "utilities/hooks/useAuthorizeUserRole";

export default function MapOrgView() {
  const { orgSelectedMark, locationsMapMarksArray, name } = useOrganizationContext();
  const smAbove = useMediaQuery("(min-width:1200px)"); //** Left bar over or half */
  const heightDesktop = "70vh";
  const heightMobile = "60vh";

  //**Functions */
  const { loadList, onSearch } = useMapOrgData(name);
  const { getMapsData, loading } = useSetGoogleMapDataOrg();

  const { isUserAuthorized } = useAutorizeUserByRole();
  useEffect(() => {
    if (!orgSelectedMark) return;
    getMapsData(orgSelectedMark);
  }, [orgSelectedMark]);

  return (
    <Box position="relative" mb={6}>
      <ClaimOrgSearch onSearch={onSearch} />
      <MapOrg
        positions={locationsMapMarksArray}
        mapHeight={smAbove ? heightDesktop : heightMobile}
        options={{
          mapTypeControlOptions: { position: 3 },
          draggable: isUserAuthorized([Roles.superAdmin, Roles.organizationOwner, Roles.organizationEditor]),
        }}
        loading={loading}
        loadList={loadList}
      />
    </Box>
  );
}
