//** React imports */
import { Suspense, lazy } from "react";
//** Components imports */
import { PrivateRoutes, PublicRoutes } from "models/routes";
import { AuthGuard, OrganizationGuard } from "guards";
import { Spinner } from "components";
import { Organizations } from "pages/organizations";
import { Locations, Users, ChangeLog, SelectOrganizationLogin } from "pages";
import { RoutesWithNotFound } from "utilities";

//** External imports */
import { BrowserRouter, Route, Navigate } from "react-router-dom";
//** Style */
import "styles/constants.css";
import AdminGuard from "guards/admin.guard";
import UsersRequestsNavigation from "pages/users/UsersRequestsNavigation";
import LoginGuard from "guards/login.guard";
import SureRoute from "pages/sureRoute/SureRoute";
import { PingIDAuth } from "pages/pingIDAuth";
import useObserverStore from "hooks/useObserverStore";
import { ListAndSearchContextProvider } from "context/ListsAndSearchContext";

//? Lazy loading
const Login = lazy(() => import("./pages/login/Login"));
const ResetPassword = lazy(() => import("./pages/resetPassword/ResetPassword"));
const ResetPasswordGetEmail = lazy(() => import("./pages/resetPassword/EmailToResetPassword"));
const ValidateAccount = lazy(() => import("./pages/validateAccount/validateAccount"));
const RequestAccess = lazy(() => import("./pages/requestAccess/RequestAccess"));
const ErrorLinkView = lazy(() => import("./pages/resetPassword/ErrorLinkView"));
const APIToken = lazy(() => import("./pages/apiToken/APIToken"));

export default function Navigation() {
  useObserverStore();
  return (
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <ListAndSearchContextProvider>
          <RoutesWithNotFound>
            <Route path="/" element={<Navigate to={PrivateRoutes.ORGANIZATIONS} />} />
            <Route element={<LoginGuard />}>
              <Route path={PublicRoutes.LOGIN} element={<Login />} />
            </Route>
            <Route path={PublicRoutes.CHANGE_PASSWORD} element={<ResetPassword />} />
            <Route path={PublicRoutes.CHANGE_PASSWORD_GET_EMAIL} element={<ResetPasswordGetEmail />} />
            <Route path={PublicRoutes.EMAIL_VERIFICATION} element={<ValidateAccount />} />
            <Route path={PublicRoutes.ACCOUNT_REQUEST} element={<RequestAccess />} />
            <Route path={PublicRoutes.ERROR_VALIDATION_LINK} element={<ErrorLinkView />} />
            <Route path={PublicRoutes.SURE_ROUTE} element={<SureRoute />} />
            <Route path={PublicRoutes.PINGID_AUTORIZED} element={<PingIDAuth />} />
            {/* User loggin validation */}
            <Route element={<AuthGuard />}>
              {/* //! ONLY LAZY INSIDE   */}
              <Route path={`${PrivateRoutes.SELECT_ORGANIZARION}/*`} element={<SelectOrganizationLogin />} />
              {/* Validate if the user have an organization select */}
              <Route element={<OrganizationGuard />}>
                {/* Validate if the user is admin or general view to go inside this views*/}
                <Route element={<AdminGuard />}>
                  <Route path={`${PrivateRoutes.CHANGELOG}/*`} element={<ChangeLog />} />
                  <Route path={`${PrivateRoutes.API_TOKEN}/*`} element={<APIToken />} />
                  <Route path={`${PrivateRoutes.USERS_REQUEST}/*`} element={<UsersRequestsNavigation />} />
                </Route>

                {/** Complete access  */}
                <Route path={`${PrivateRoutes.ORGANIZATIONS}/*`} element={<Organizations />} />
                <Route path={`${PrivateRoutes.LOCATIONS}/*`} element={<Locations />} />
                <Route path={`${PrivateRoutes.USERS}/*`} element={<Users />} />
              </Route>
            </Route>
          </RoutesWithNotFound>
        </ListAndSearchContextProvider>
      </BrowserRouter>
    </Suspense>
  );
}
