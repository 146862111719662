import { lazy } from "react";

//**Mui imports */
import { Fade, Theme, useMediaQuery } from "@mui/material";
//** UTILS */
//**Hooks */
import { useParams } from "react-router-dom";
//** Components imports */
import { LocationContextProvider } from "pages/locations";
import useOrganization from "../../hooks/useOrganization";

//**Lazy */
const OrgRespLanding = lazy(() => import("pages/organizations/components/responsiveComponents/organism/OrgRespLanding/OrgRespLanding"));
const OrganizationLanding = lazy(() => import("pages/organizations/components/organism/OrganizationLanding/OrganizationLanding"));

export default function OrganizationInfoView() {
  //**Hooks */
  const { id } = useParams();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  //**Context */
  const { loading } = useOrganization(`${id}`);

  return (
    <LocationContextProvider>
      <Fade in unmountOnExit timeout={500}>
        <div>{underMd ? <OrgRespLanding loading={loading} /> : <OrganizationLanding loading={loading} />}</div>
      </Fade>
    </LocationContextProvider>
  );
}
