/* eslint-disable no-shadow */
import React, { useState, createContext, useContext, SetStateAction, Dispatch, useMemo } from "react";
import { ICoordinatesDashboard } from "components/atoms/Map/MapOrg/MapOrg";
import { IPrimaryContactDOM } from "pages/locations";
import { IDropdownOption } from "models";
import { ICoordinates } from "components/atoms/Map/Maps";
import { IOrgMapsListDOM } from "../models";

/*
The idea of this context it is allow a mechanism to pass the information between the components of the page.
*/

interface IGoogleMapOrgInput {
  address1: string;
  market: string;
  city: string;
  state: string;
  postalCode: string;
  coordinates_latitude: string;
  coordinates_longitude: string;
  phone: string;
  website: string;

  [index: string]: any;
}

type contextValues = {
  email: string | null;
  setEmail: Dispatch<SetStateAction<string | null>>;
  password: string | null;
  setPassword: Dispatch<SetStateAction<string | null>>;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  url: string;
  setUrl: Dispatch<SetStateAction<string>>;
  description: string;
  setDescription: Dispatch<SetStateAction<string>>;
  logo: File[];
  setLogo: Dispatch<SetStateAction<File[]>>;
  cleatBasicInformation: Function;
  logoText: string;
  setLogoText: Dispatch<SetStateAction<string>>;
  initalUrl: string;
  setInitalUrl: Dispatch<SetStateAction<string>>;

  nameLocSearchOrg: string;
  setNameLocSearchOrg: Dispatch<SetStateAction<string>>;
  claimStateSearchOrg: string;
  setClaimStateSearchOrg: Dispatch<SetStateAction<string>>;
  claimCitySearchOrg: string;
  setClaimCitySearchOrg: Dispatch<SetStateAction<string>>;
  claimCountrySearchOrg: IDropdownOption;
  setClaimCountrySearchOrg: Dispatch<SetStateAction<IDropdownOption>>;
  claimSearchToggle: boolean;
  setClaimSearchToggle: Dispatch<SetStateAction<boolean>>;
  phoneOrganization: string;
  setPhoneOrganization: Dispatch<SetStateAction<string>>;
  emailOrganization: string | null;
  setEmailOrganization: Dispatch<SetStateAction<string | null>>;
  Address1Organization: string;
  setAddress1Organization: Dispatch<SetStateAction<string>>;
  Address2Organization: string;
  setAddress2Organization: Dispatch<SetStateAction<string>>;
  countryOrganization: IDropdownOption;
  setCountryOrganization: Dispatch<SetStateAction<IDropdownOption>>;
  cityOrganization: string;
  setCityOrganization: Dispatch<SetStateAction<string>>;
  stateOrganization: string;
  setStateOrganization: Dispatch<SetStateAction<string>>;
  postalCodeOrganization: string;
  setPostalCodeOrganization: Dispatch<SetStateAction<string>>;
  latOrganization: string;
  setLatOrganization: Dispatch<SetStateAction<string>>;
  longOrganization: string;
  setLongOrganization: Dispatch<SetStateAction<string>>;
  mapId: string;
  setMapId: Dispatch<SetStateAction<string>>;
  isValidEmail: boolean;
  setIsValidEmail: Dispatch<SetStateAction<boolean>>;
  regionOrganization: IDropdownOption;
  setRegionOrganization: Dispatch<SetStateAction<IDropdownOption>>;
  alertRequiredFields: boolean;
  setAlertRequiredFields: Dispatch<SetStateAction<boolean>>;
  locationsMapListArray: IOrgMapsListDOM[];
  setLocationsMapListArray: Dispatch<SetStateAction<IOrgMapsListDOM[]>>;
  locationsMapMarksArray: ICoordinatesDashboard[];
  setLocationsMapMarksArray: Dispatch<SetStateAction<ICoordinatesDashboard[]>>;
  orgSelectedMark: string;
  setOrgSelectedMark: Dispatch<SetStateAction<string>>;
  googleMapsOrgInputs: IGoogleMapOrgInput | null;
  setGoogleMapsOrgInputs: Dispatch<SetStateAction<IGoogleMapOrgInput | null>>;
  activeLocationItemList: string[];
  setActiveLocationItemList: Dispatch<SetStateAction<string[]>>;
  activeLocationItemListCoordinates: ICoordinates[];
  setActiveLocationItemListCoordinates: Dispatch<SetStateAction<ICoordinates[]>>;
  orgLatitudeMap: number | undefined;
  setOrgLatitudeMap: Dispatch<SetStateAction<number | undefined>>;
  orgLongitudeMap: number | undefined;
  setOrgLongitudeMap: Dispatch<SetStateAction<number | undefined>>;
  toggleChangeCoordinates: boolean;
  setToggleChangeCoordinates: Dispatch<SetStateAction<boolean>>;
  category: string[];
  setCategory: Dispatch<SetStateAction<string[]>>;
  applicableBrand: IDropdownOption[];
  setApplicableBrand: Dispatch<SetStateAction<IDropdownOption[]>>;
  purpose: IDropdownOption[];
  setPurpose: Dispatch<SetStateAction<IDropdownOption[]>>;
  isPremium: boolean;
  setIsPremium: Dispatch<SetStateAction<boolean>>;
  isFactory: boolean;
  setIsFactory: Dispatch<SetStateAction<boolean>>;
  isOnlineOnly: boolean;
  setIsOnlineOnly: Dispatch<SetStateAction<boolean>>;
  business: string;
  setBusiness: Dispatch<SetStateAction<string>>;
  displayOrder: string;
  setDisplayOrder: Dispatch<SetStateAction<string>>;
  brandsPictures: File[];
  setBrandsPictures: Dispatch<SetStateAction<File[]>>;
  brandsPicturesText: string[];
  setBrandsPicturesText: Dispatch<SetStateAction<string[]>>;
  locHqId: string;
  setLocHqId: Dispatch<SetStateAction<string>>;
  locationHq: IDropdownOption;
  setLocationHq: Dispatch<SetStateAction<IDropdownOption>>;
  newHqData: any;
  setNewHqData: Dispatch<SetStateAction<any>>;
  hqPrimaryContact: IDropdownOption;
  setHqPrimaryContact: Dispatch<SetStateAction<IDropdownOption>>;
  primaryContact: IPrimaryContactDOM | null;
  setPrimaryContact: Dispatch<SetStateAction<IPrimaryContactDOM | null>>;
  locationHQName: string;
  setLocationHQName: Dispatch<SetStateAction<string>>;
};

const defaultValues = {
  email: null,
  setEmail: () => {},
  password: null,
  setPassword: () => {},
  name: "",
  setName: () => {},
  url: "",
  setUrl: () => {},
  description: "",
  setDescription: () => {},
  logo: [],
  setLogo: () => {},
  cleatBasicInformation: () => {},
  logoText: "",
  setLogoText: () => {},
  initalUrl: "",
  setInitalUrl: () => {},
  phoneOrganization: "",
  setPhoneOrganization: () => {},
  emailOrganization: null,
  setEmailOrganization: () => {},
  Address1Organization: "",
  setAddress1Organization: () => {},
  Address2Organization: "",
  setAddress2Organization: () => {},
  countryOrganization: { label: "", id: "" },
  setCountryOrganization: () => {},
  cityOrganization: "",
  setCityOrganization: () => {},
  stateOrganization: "",
  setStateOrganization: () => {},
  postalCodeOrganization: "",
  setPostalCodeOrganization: () => {},
  latOrganization: "",
  setLatOrganization: () => {},
  longOrganization: "",
  setLongOrganization: () => {},
  mapId: "",
  setMapId: () => {},
  isValidEmail: true,
  setIsValidEmail: () => {},
  regionOrganization: { label: "", id: "" },
  setRegionOrganization: () => {},
  alertRequiredFields: false,
  setAlertRequiredFields: () => {},
  locationsMapListArray: [],
  setLocationsMapListArray: () => {},
  locationsMapMarksArray: [],
  setLocationsMapMarksArray: () => {},
  orgSelectedMark: "",
  setOrgSelectedMark: () => {},
  googleMapsOrgInputs: null,
  setGoogleMapsOrgInputs: () => {},
  activeLocationItemList: [],
  setActiveLocationItemList: () => {},
  activeLocationItemListCoordinates: [],
  setActiveLocationItemListCoordinates: () => {},
  orgLatitudeMap: 0,
  setOrgLatitudeMap: () => {},
  orgLongitudeMap: 0,
  setOrgLongitudeMap: () => {},
  //organizations
  claimStateSearchOrg: "",
  setClaimStateSearchOrg: () => {},
  claimCitySearchOrg: "",
  setClaimCitySearchOrg: () => {},
  claimCountrySearchOrg: { label: "", id: "" },
  setClaimCountrySearchOrg: () => {},
  claimSearchToggle: false,
  setClaimSearchToggle: () => {},
  nameLocSearchOrg: "",
  setNameLocSearchOrg: () => {},

  toggleChangeCoordinates: false,
  setToggleChangeCoordinates: () => {},
  category: [],
  setCategory: () => {},
  applicableBrand: [],
  setApplicableBrand: () => {},
  purpose: [],
  setPurpose: () => {},
  isPremium: false,
  setIsPremium: () => {},
  isFactory: false,
  setIsFactory: () => {},
  isOnlineOnly: false,
  setIsOnlineOnly: () => {},
  business: "",
  setBusiness: () => {},
  displayOrder: "",
  setDisplayOrder: () => {},
  brandsPictures: [],
  setBrandsPictures: () => {},
  brandsPicturesText: [],
  setBrandsPicturesText: () => {},
  locHqId: "",
  setLocHqId: () => {},
  locationHq: { label: "", id: "" },
  setLocationHq: () => {},
  //organizations
  newHqData: {},
  setNewHqData: () => {},
  hqPrimaryContact: { label: "", id: "" },
  setHqPrimaryContact: () => {},
  primaryContact: null,
  setPrimaryContact: () => {},
  locationHQName: "",
  setLocationHQName: () => {},
};

const OrganizationContext = createContext<contextValues>(defaultValues);

const OrganizationContextProvider = (props: any) => {
  const { children } = props;

  //Array to render items
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);

  //** Basic information*/
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [logo, setLogo] = useState<File[]>([]);
  const [logoText, setLogoText] = useState<string>("");
  const [initalUrl, setInitalUrl] = useState<string>("https://");

  //**Locations */
  const [claimSearchToggle, setClaimSearchToggle] = useState<boolean>(false);
  const [newHqData, setNewHqData] = useState<any>();

  //** Search */
  const [nameLocSearchOrg, setNameLocSearchOrg] = useState<string>("");
  const [claimStateSearchOrg, setClaimStateSearchOrg] = useState<string>("");
  const [claimCitySearchOrg, setClaimCitySearchOrg] = useState<string>("");
  const [claimCountrySearchOrg, setClaimCountrySearchOrg] = useState<IDropdownOption>({ label: "", id: "" });

  //** Contact */
  const [locationHQName, setLocationHQName] = useState<string>("");
  const [locHqId, setLocHqId] = useState<string>("");
  const [locationHq, setLocationHq] = useState<IDropdownOption>({ label: "", id: "" });
  const [phoneOrganization, setPhoneOrganization] = useState<string>("");
  const [emailOrganization, setEmailOrganization] = useState<string | null>(null);
  const [url, setUrl] = useState<string>("");
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [hqPrimaryContact, setHqPrimaryContact] = useState<IDropdownOption>({ label: "", id: "" });
  const [primaryContact, setPrimaryContact] = useState<IPrimaryContactDOM | null>(null);

  //** Address */
  const [Address1Organization, setAddress1Organization] = useState<string>("");
  const [Address2Organization, setAddress2Organization] = useState<string>("");
  const [countryOrganization, setCountryOrganization] = useState<IDropdownOption>({ label: "", id: "" });
  const [regionOrganization, setRegionOrganization] = useState<IDropdownOption>({ label: "", id: "" });
  const [cityOrganization, setCityOrganization] = useState<string>("");
  const [stateOrganization, setStateOrganization] = useState<string>("");
  const [postalCodeOrganization, setPostalCodeOrganization] = useState<string>("");
  const [latOrganization, setLatOrganization] = useState<string>("");
  const [longOrganization, setLongOrganization] = useState<string>("");
  const [mapId, setMapId] = useState<string>("");

  const [alertRequiredFields, setAlertRequiredFields] = useState<boolean>(false);

  //**Maps */
  const [locationsMapListArray, setLocationsMapListArray] = useState<IOrgMapsListDOM[]>([]);
  const [locationsMapMarksArray, setLocationsMapMarksArray] = useState<ICoordinatesDashboard[]>([]);
  const [orgSelectedMark, setOrgSelectedMark] = useState<string>("");
  const [googleMapsOrgInputs, setGoogleMapsOrgInputs] = useState<IGoogleMapOrgInput | null>(null);
  const [activeLocationItemList, setActiveLocationItemList] = useState<string[]>([]);
  const [activeLocationItemListCoordinates, setActiveLocationItemListCoordinates] = useState<ICoordinates[]>([]);

  const [orgLatitudeMap, setOrgLatitudeMap] = useState<number | undefined>(undefined);
  const [orgLongitudeMap, setOrgLongitudeMap] = useState<number | undefined>(undefined);
  const [toggleChangeCoordinates, setToggleChangeCoordinates] = useState<boolean>(false);

  //**Business */
  const [category, setCategory] = useState<string[]>([]);
  const [applicableBrand, setApplicableBrand] = useState<IDropdownOption[]>([]);
  const [purpose, setPurpose] = useState<IDropdownOption[]>([]);
  const [isPremium, setIsPremium] = useState<boolean>(false);
  const [isFactory, setIsFactory] = useState<boolean>(false);
  const [isOnlineOnly, setIsOnlineOnly] = useState<boolean>(false);
  const [business, setBusiness] = useState<string>("");
  const [displayOrder, setDisplayOrder] = useState<string>("");
  const [brandsPictures, setBrandsPictures] = useState<File[]>([]);
  const [brandsPicturesText, setBrandsPicturesText] = useState<string[]>([]);

  //** Funtions to clear the states */
  const cleatBasicInformation = () => {
    setName("");
    setUrl("");
    setDescription("");
    setLogo([]);
    setLogoText("");
    setPhoneOrganization("");
    setEmailOrganization(null);
    setAddress1Organization("");
    setAddress2Organization("");
    setCountryOrganization({ label: "", id: "" });
    setCityOrganization("");
    setStateOrganization("");
    setPostalCodeOrganization("");
    setLatOrganization("");
    setLongOrganization("");
    setIsValidEmail(true);
    setRegionOrganization({ label: "", id: "" });
    setAlertRequiredFields(false);
    setMapId("");
    setOrgSelectedMark("");
    setGoogleMapsOrgInputs(null);
    setActiveLocationItemList([]);
    setActiveLocationItemListCoordinates([]);
    setLocationsMapMarksArray([]);
    setLocationsMapListArray([]);
    setOrgLatitudeMap(undefined);
    setOrgLongitudeMap(undefined);
    setNameLocSearchOrg("");
    setCategory([]);
    setApplicableBrand([]);
    setPurpose([]);
    setIsPremium(false);
    setIsFactory(false);
    setIsOnlineOnly(false);
    setBusiness("");
    setDisplayOrder("");
    setBrandsPictures([]);
    setBrandsPicturesText([]);
    setLocHqId("");
    setNewHqData({});
    setHqPrimaryContact({ label: "", id: "" });
    setPrimaryContact(null);
    setLocationHQName("");
  };
  const contextValues = useMemo(
    () => ({
      email,
      setEmail,
      password,
      setPassword,
      name,
      setName,
      url,
      setUrl,
      description,
      setDescription,
      logo,
      setLogo,
      cleatBasicInformation,
      logoText,
      setLogoText,
      initalUrl,
      setInitalUrl,
      phoneOrganization,
      setPhoneOrganization,
      emailOrganization,
      setEmailOrganization,
      Address1Organization,
      setAddress1Organization,
      Address2Organization,
      setAddress2Organization,
      countryOrganization,
      setCountryOrganization,
      cityOrganization,
      setCityOrganization,
      stateOrganization,
      setStateOrganization,
      postalCodeOrganization,
      setPostalCodeOrganization,
      latOrganization,
      setLatOrganization,
      longOrganization,
      setLongOrganization,
      mapId,
      setMapId,
      isValidEmail,
      setIsValidEmail,
      regionOrganization,
      setRegionOrganization,
      alertRequiredFields,
      setAlertRequiredFields,
      locationsMapListArray,
      setLocationsMapListArray,
      locationsMapMarksArray,
      setLocationsMapMarksArray,
      orgSelectedMark,
      setOrgSelectedMark,
      googleMapsOrgInputs,
      setGoogleMapsOrgInputs,
      activeLocationItemList,
      setActiveLocationItemList,
      activeLocationItemListCoordinates,
      setActiveLocationItemListCoordinates,
      orgLatitudeMap,
      setOrgLatitudeMap,
      orgLongitudeMap,
      setOrgLongitudeMap,
      nameLocSearchOrg,
      setNameLocSearchOrg,
      claimStateSearchOrg,
      setClaimStateSearchOrg,
      claimCitySearchOrg,
      setClaimCitySearchOrg,
      claimCountrySearchOrg,
      setClaimCountrySearchOrg,
      claimSearchToggle,
      setClaimSearchToggle,
      toggleChangeCoordinates,
      setToggleChangeCoordinates,
      category,
      setCategory,
      applicableBrand,
      setApplicableBrand,
      purpose,
      setPurpose,
      isPremium,
      setIsPremium,
      isFactory,
      setIsFactory,
      isOnlineOnly,
      setIsOnlineOnly,
      business,
      setBusiness,
      displayOrder,
      setDisplayOrder,
      brandsPictures,
      setBrandsPictures,
      brandsPicturesText,
      setBrandsPicturesText,
      locHqId,
      setLocHqId,
      locationHq,
      setLocationHq,
      newHqData,
      setNewHqData,
      hqPrimaryContact,
      setHqPrimaryContact,
      primaryContact,
      setPrimaryContact,
      locationHQName,
      setLocationHQName,
    }),
    [
      email,
      password,
      name,
      url,
      setUrl,
      description,
      logo,
      setLogo,
      cleatBasicInformation,
      logoText,
      initalUrl,
      phoneOrganization,
      emailOrganization,
      Address1Organization,
      Address2Organization,
      countryOrganization,
      cityOrganization,
      stateOrganization,
      postalCodeOrganization,
      latOrganization,
      longOrganization,
      mapId,
      isValidEmail,
      regionOrganization,
      alertRequiredFields,
      locationsMapListArray,
      locationsMapMarksArray,
      orgSelectedMark,
      googleMapsOrgInputs,
      activeLocationItemList,
      activeLocationItemListCoordinates,
      orgLatitudeMap,
      orgLongitudeMap,
      nameLocSearchOrg,
      claimStateSearchOrg,
      claimCitySearchOrg,
      claimCountrySearchOrg,
      claimSearchToggle,
      toggleChangeCoordinates,
      category,
      setCategory,
      applicableBrand,
      purpose,
      isPremium,
      isFactory,
      isOnlineOnly,
      business,
      displayOrder,
      brandsPictures,
      brandsPicturesText,
      locHqId,
      locationHq,
      newHqData,
      hqPrimaryContact,
      primaryContact,
      locationHQName,
    ],
  );

  return <OrganizationContext.Provider value={contextValues}>{children}</OrganizationContext.Provider>;
};

export const useOrganizationContext = () => {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error("OrganizationContext must be used within a OrganizationContextProvider");
  }
  return context;
};

export { OrganizationContext, OrganizationContextProvider };
